<div class="input-container" [class.clickable]="clickable">
  <div *ngIf="label.length > 0" class="input-label">
    {{ label }}
  </div>
  <div class="input-container">
    <input #input *ngIf="!isForm" [ngClass]="['input-' + inputStyle, disabled ? 'disabled' : '', getAlarmBoxClass()]"
      [class.clickable]="clickable" [type]="type != 'password' ? type : showPassword ? 'text' : 'password'"
      [placeholder]="placeholder" [(ngModel)]="value" (input)="onInputChange($event)" [readOnly]="readonly || disabled"
      (focus)="registerOnTouched($event)" min="0" [max]="maxRange"
      (keydown)="onKeyPress ? onKeyPress($event, value) : null" />
    <input #input *ngIf="isForm" [ngClass]="[
        control.invalid && control.touched ? 'input-invalid' : '',
        'input-' + inputStyle, disabled ? 'disabled' : ''
      ]" [type]="type != 'password' ? type : showPassword ? 'text' : 'password'" [placeholder]="placeholder"
      [formControl]="control" [readOnly]="readonly || disabled" min="0" [max]="maxRange"
      (keydown)="onKeyPress ? onKeyPress($event, value) : null" />
    <img *ngIf="type == 'password'" (click)="togglePwdVisibility()" class="pass-icon" src="{{
        showPassword
          ? 'assets/img/icons/ic-eye-closed.svg'
          : 'assets/img/icons/ic-eye-open.svg'
      }}" alt="" />
  </div>
</div>