import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ERoleCMS } from '../data-types/cms-roles';
import { ROOTS_CLIENTS } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    private _authService: AuthService,
    private _router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this._authService.getToken()) {
      const role = this._authService.getRole();
      if (role == ERoleCMS.CLIENT) {
        if (!this.isRouteAllowed(state.url)) {
          this._router.navigate(['/stations']);
          return false;
        }
      }
      return true;
    }
    this._router.navigate(['/login']);
    return false;
  }

  private isRouteAllowed(url: string): boolean {
    return ROOTS_CLIENTS.includes(url);
  }
}
