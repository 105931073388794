import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class MetricsService {
    private API_URL = environment.API_URL;

    constructor(
        private http: HttpClient
    ) { }

    getMetricsType0(query: any) {
        return this.http.get(`${this.API_URL}/booking-metrics?requestType=0${query}`);
    }

    getMetricsType1(query: any) {
        return this.http.get(`${this.API_URL}/booking-metrics?requestType=1${query}`);
    }

    getMetricsType2(query: any) {
        return this.http.get(`${this.API_URL}/booking-metrics?requestType=2${query}`);
    }

    getMetricsType3(query: any) {
        return this.http.get(`${this.API_URL}/booking-metrics?requestType=3${query}`);
    }
}
