import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared.module";
import { BackofficeMenuComponent } from "./backoffice-menu.component";

@NgModule({
  declarations: [
    BackofficeMenuComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    BackofficeMenuComponent
  ]
})
export class BackofficeMenuModule {}
