import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared.module";
import { BackofficeLoadingModule } from "../backoffice-loading/backoffice-loading.module";
import { BackofficeHeaderComponent } from "./backoffice-header.component";

@NgModule({
  declarations: [
    BackofficeHeaderComponent
  ],
  imports: [
    SharedModule,
    BackofficeLoadingModule
  ],
  exports: [
    BackofficeHeaderComponent
  ]
})
export class BackofficeHeaderModule {}
