import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared.module";
import { BackofficeButtonModule } from "../backoffice-button/backoffice-button.module";
import { BackofficeModalComponent } from "./backoffice-modal.component";
import { BackofficeSwitchModule } from "../backoffice-switch/backoffice-switch.module";

@NgModule({
  declarations: [
    BackofficeModalComponent
  ],
  imports: [
    SharedModule,
    BackofficeButtonModule,
    BackofficeSwitchModule
  ],
  exports: [
    BackofficeModalComponent
  ]
})
export class BackofficeModalModule {}
