import { Action, createReducer, on } from "@ngrx/store";
import { editSelectStationAction, getStationsAction, getStationsSuccessAction, updateBoxSelectedStationAction } from "./stations.actions";
import { initialStationsListState, IStationsListState } from "./stations.state";

export const stationReducer = createReducer(
  initialStationsListState,
  on(getStationsAction, (state: IStationsListState) => {
    return ({
      ...state
    })
  }),
  on(getStationsSuccessAction, (state: IStationsListState, { stationsList }) => {
    return ({
      ...state,
      isLoading: false,
      totalElements: stationsList.totalElements,
      totalPages: stationsList.totalPages,
      stations: stationsList.stations
    })
  }),
  on(editSelectStationAction, (state: IStationsListState, { station }) => {
    return ({
      ...state,
      selectedStation: station
    })
  }),
  on(updateBoxSelectedStationAction, (state: IStationsListState, { box }) => {
    let boxes = [...state.selectedStation.boxes]

    let index = boxes.findIndex(item => {
      return item.id == box.id
    })
    if (index != -1) {
      boxes[index].assignedTo = null
      boxes[index].initOccupation = null
    }

    return ({
      ...state,
      selectedStation: { ...state.selectedStation, boxes: boxes }
    })
  })
)

export function stationsReducers(state: IStationsListState = initialStationsListState, action: Action) {
  return stationReducer(state, action)
}
