export interface IBookingIncidenceState {
  assignedTo: number,
  bookingId: number,
  bookingStatusName: string,
  boxId: number,
  boxName: string,
  confirmedDate: Date,
  durationMinutes: number,
  finishDate: Date,
  penalty: boolean,
  stationId: number,
  stationName: string
}

export interface IBookingIncidencesListState {
  bookings: IBookingIncidenceState[],
  totalPages: number,
  totalElements: number,
  isLoading: boolean
}

export const initialBookingIncidencesListState: IBookingIncidencesListState = {
  totalElements: 0,
  totalPages: 1,
  bookings: [],
  isLoading: true
}
