import { Action, createReducer, on } from "@ngrx/store";
import { IBookingIncidencesListState, initialBookingIncidencesListState } from "./booking-incidences.state";
import { getBookingIncidencesSuccessAction } from "./booking-incidences.actions";

export const bookingIncidencesReducer = createReducer(
  initialBookingIncidencesListState,
  on(getBookingIncidencesSuccessAction, (state: IBookingIncidencesListState, { incidencesList }) => {
    return ({
      ...state,
      isLoading: false,
      totalElements: incidencesList.totalElements,
      totalPages: incidencesList.totalPages,
      bookings: incidencesList.bookings
    })
  })
)

export function bookingIncidencesReducers(state: IBookingIncidencesListState = initialBookingIncidencesListState, action: Action) {
  return bookingIncidencesReducer(state, action)
}
