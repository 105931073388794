import { createReducer, on, Action } from '@ngrx/store';
import { editSelectClientAction, getClientsAction, getClientsSuccessAction } from './clients.actions';
import { initialClientsListState, IClientsListState } from './clients.state';

export const clientReducer = createReducer(
  initialClientsListState,
  on(getClientsAction, (state: IClientsListState) => {
    return ({
      ...state
    })
  }),
  on(getClientsSuccessAction, (state: IClientsListState, { clientsList }) => {
    return ({
      ...state,
      totalElements: clientsList.totalElements,
      totalPages: clientsList.totalPages,
      clients: clientsList.clients
    })
  }),
  on(editSelectClientAction, (state: IClientsListState, { client }) => {
    return ({
      ...state,
      selectedClient: client
    })
  })
)

export function clientsReducers(state: IClientsListState = initialClientsListState, action: Action) {
  return clientReducer(state, action)
}
