import { formatDate, registerLocaleData } from '@angular/common';
import { Injectable } from '@angular/core';
import localeES from "@angular/common/locales/es";
import { EStationControls } from '../constants';
registerLocaleData(localeES, "es");

@Injectable({
  providedIn: 'root'
})
export class ExportsService {

  constructor() { }

  private unwantedColumns = [
    'banned',
    'subscription',
    'currentPeriodEnd',
    'currentPeriodStart'
  ];
  private dateRegex = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;

  generateCSVString(object: any) {
    let CSVString = '';
    let contentArray = this.getContentArrays(object);
    contentArray.forEach((row) => {
      CSVString += row + "\n";
    })
    return CSVString;
  }

  private getContentArrays(object: any) {
    let result = [];
    const keys = Object.keys(object[0]).filter(k => !this.unwantedColumns.includes(k));
    result.push([keys.join()]);
    object.forEach((o: any) => {
      let row = [];
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (!this.unwantedColumns.includes(key)) {
          let value = o[key];
          if (this.dateRegex.test(value)) {
            value = formatDate(new Date(value), 'dd/MM/YYYY HH:mm', "es-ES");
          }
          row.push(value);
        }
      }
      result.push(row.join());
    })
    return result;
  }

  generateAndDownloadCSV(content: string, fileName: string) {
    const blob = new Blob([content], { type: 'application/csv' });
    const link = document.createElement('a');
    link.setAttribute('href', window.URL.createObjectURL(blob));
    link.setAttribute('download', `${fileName}.csv`);
    link.click();
  }

  getTopic(control) {

    let topic;

    switch (control) {
      case EStationControls.MUPI.manual:
        topic = 0;
        break;
      case EStationControls.OUTDOOR_LIGHT.manual:
        topic = 1;
        break;
      case EStationControls.INDOOR_LIGHT.manual:
        topic = 2;
        break;
      case EStationControls.BATTERY.manual:
        topic = 3;
        break;
      default:
        return null
    }

    return topic
  }


}
