import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { EStorageType } from '../../data-types/storage.enum';
import { IMenuItem } from '../../models/menu.interface';
import { ERoleCMS } from '../../data-types/cms-roles';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'backoffice-menu',
  templateUrl: './backoffice-menu.component.html',
  styleUrls: ['./backoffice-menu.component.scss']
})
export class BackofficeMenuComponent implements OnInit {

  @Input("disabled") disabled: boolean = false;
  @Output("onClick") onClick: any = new EventEmitter();
  user: any;
  rol: any
  isClient: boolean = false;
  userLogged: boolean = true;
  currentRoute: string = "";
  items: Array<IMenuItem> = [
    {
      id: 0,
      text_key: 'MENU.USERS',
      path: 'users',
      iconActive: 'assets/img/icons/user-active.png',
      iconInactive: 'assets/img/icons/user.png',
    },
    {
      id: 1,
      text_key: 'MENU.STATIONS',
      path: 'stations',
      iconActive: 'assets/img/icons/station-active.png',
      iconInactive: 'assets/img/icons/station.png',
    },
    {
      id: 2,
      text_key: 'MENU.CLIENTS',
      path: 'clients',
      iconActive: 'assets/img/icons/customers-icon-green.svg',
      iconInactive: 'assets/img/icons/customers-icon-black.svg',
    },
    {
      id: 3,
      text_key: 'MENU.INCIDENCES',
      path: 'incidences',
      iconActive: 'assets/img/icons/incidence-active.png',
      iconInactive: 'assets/img/icons/incidence.png',
    }, {
      id: 4,
      text_key: 'MENU.STATICS',
      path: 'statistics',
      iconActive: 'assets/img/icons/analytics-active.png',
      iconInactive: 'assets/img/icons/analytics.png',
    },
  ];
  selectedItem: number = 0;

  private undestroy$$ = new Subject<void>()
  private _authService = this.injector.get(AuthService);


  constructor(
    private router: Router,
    private injector: Injector,
  ) { }



  ngOnInit(): void {
    const user: any = localStorage.getItem(EStorageType.User);
    const role = this._authService.getRole();

    if (user !== null) {
      this.userLogged = true;
      this.user = JSON.parse(user);
      this.rol = role === ERoleCMS.ADMIN ? 'ADMIN' : ERoleCMS.CLIENT && (this.isClient = true, 'CLIENT');
      if (this.isClient) {
        this.selectedItem = 1;
      } else {
        this.selectedItem = 1;
      }
    }
    else {
      this.user = null;
    }
    this.router.events.subscribe({
      next: (event: Event) => {
        if (event instanceof NavigationEnd) {
          this.currentRoute = event.url.split('/')[1];
          this.selectedItem = this.items.find(it => it.path.includes(this.currentRoute))?.id;
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.undestroy$$.next()
    this.undestroy$$.complete()
  }

  public selectItem(event: MouseEvent, index: number) {
    this.selectedItem = index;
    this.router.navigate([this.items[index].path]);
  }

  public isItemSelected(index: number): boolean {
    return this.selectedItem === index;

  }

}
