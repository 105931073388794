import { createReducer, on, Action } from '@ngrx/store';
import { editSelectUserAction, getUsersAction, getUsersSuccessAction } from './users.actions';
import { initialUsersListState, IUsersListState } from './users.state';

export const usersReducer = createReducer(
  initialUsersListState,
  on(getUsersAction, (state: IUsersListState) => {
    return ({
      ...state
    })
  }),
  on(getUsersSuccessAction, (state: IUsersListState, {usersList}) => {
    return ({
      ...state,
      totalElements: usersList.totalElements,
      totalPages: usersList.totalPages,
      users: usersList.users
    })
  }),
  on(editSelectUserAction, (state: IUsersListState, {user}) => {
    return ({
      ...state,
      selectedUser: user
    })
  })
)

export function usersReducers(state: IUsersListState = initialUsersListState, action: Action) {
  return usersReducer(state, action)
}
