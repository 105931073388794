import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { EButtonStyles } from '../../data-types/button.enum';
import { EDataTypes } from '../../data-types/data_types.enum';
import { KEYCODES } from '../../data-types/keyCodes.enum';
import { initialTable, ITable } from '../../models/table.interface';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ERoleCMS } from '../../data-types/cms-roles';

@Component({
  selector: 'backoffice-table',
  templateUrl: './backoffice-table.component.html',
  styleUrls: ['./backoffice-table.component.scss']
})
export class BackofficeTableComponent implements OnInit {

  @Input() table: ITable = initialTable;
  @Input() searchPlaceholder: string = 'LABELS.SEARCH';
  @Input() createLabel: string = 'LABELS.CREATE';
  @Input() dateFormat: string = 'dd/MM/YY';
  @Input() currentPage: number = 0;
  @Input() totalPages: number = 1;
  @Input() showTopBar: boolean = true;
  @Input() allowExport: boolean = false;
  @Input() allowEdit: boolean = true;
  @Input() allowDelete: boolean = true;
  @Input() hasActions: boolean = true;
  @Output() onSearchEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onExportGlobalEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCreateEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onExportRowEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEditEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDeleteEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onNextPageEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPreviousPageEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClearClientToStationEmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() typeCompoent: any
  @Input() activeTab: number
  searchIdPlaceholder: string = 'LABELS.SEARCH_CLIENTS.COPMANY_ID';
  searchCompanyNamePlaceholder: string = 'LABELS.SEARCH_CLIENTS.COPMANY_NAME';
  searchNifPlaceholder: string = 'LABELS.SEARCH_CLIENTS.COPMANY_NIF';
  isClient: boolean = false;
  roleClient: ERoleCMS = ERoleCMS.CLIENT;

  @Input() chargeIncidencesloading
  @Input() doorIncidencesloading
  @Input() alarmIncidencesloading
  @Input() getStationsActionloading
  @Input() bookingIncidencesloading

  @Input() chargeIncidencesError
  @Input() doorIncidencesError
  @Input() alarmIncidencesError


  public filter = {
    companyName: '',
    searchTextNif: '',
    searchTextId: '',
    searchText: ''
  }

  public filtered: boolean = false;
  public EButtonStyles = EButtonStyles;
  public EDataTypes = EDataTypes;

  constructor(
    private router: Router,
    private injector: Injector,
  ) { }
  private _authService = this.injector.get(AuthService);

  ngOnInit(): void {
    this._authService.getRole() == ERoleCMS.CLIENT ? this.isClient = true : this.isClient = false
  }

  onSearchKeyPress(ev: any, type: string) {
    this.isFilterEmpty()
    if (ev.keyCode == KEYCODES.ENTER) {
      if (!this.filtered) {
        this.filtered = true;
        if (this.typeCompoent === 'clients') {
          this.onSearchEmit.emit({ companyName: this.filter.companyName, nif: this.filter.searchTextNif, id: this.filter.searchTextId });
        } else {
          this.onSearchEmit.emit(this.filter.searchText);
        }
      }
    } else if (ev.keyCode == KEYCODES.BACKSPACE) {
      if (this.filtered && this.filter.companyName == "" || this.filter.searchTextNif == "" || this.filter.searchTextId == "" || this.filter.searchText == "") {
        this.filtered = false;
        if (this.typeCompoent === 'clients') {
          this.onSearchEmit.emit({ companyName: this.filter.companyName, nif: this.filter.searchTextNif, id: this.filter.searchTextId });
        } else {
          this.onSearchEmit.emit(this.filter.searchText);
        }
      }
    } else if (this.filtered) {
      if (this.filter.companyName == '') {
        this.onSearchEmit.emit(this.filter.companyName);
      } if (this.filter.searchTextNif == '') {
        this.onSearchEmit.emit(this.filter.searchTextNif);
      } if (this.filter.searchTextId == '') {
        this.onSearchEmit.emit(this.filter.searchTextId);
      } if (this.filter.searchText == '') {
        this.onSearchEmit.emit(this.filter.searchText);
      }
      this.filtered = false;
    }
  }

  onSearch() {
    if (this.filtered) {
      this.filtered = false;
      this.filter.searchText = "";
      this.filter.companyName = "";
      this.filter.searchTextNif = "";
      this.filter.searchTextId = "";

    } else {
      this.filtered = true;
    }
    if (this.typeCompoent === 'clients') {
      this.onSearchEmit.emit({ companyName: this.filter.companyName, nif: this.filter.searchTextNif, id: this.filter.searchTextId });
    } else {
      this.onSearchEmit.emit(this.filter.searchText);
    }
  }

  onExportGlobal() {
    this.onExportGlobalEmit.emit();
  }

  onCreate() {
    this.onCreateEmit.emit();
  }

  onExportRow(row: any) {
    this.onExportRowEmit.emit(row);
  }

  onEdit(row: any) {
    this.onEditEmit.emit(row);
  }

  onDelete(row: any) {
    this.onDeleteEmit.emit(row);
  }
  clearClientToStation(row: any) {
    this.onClearClientToStationEmit.emit(row);
  }

  onPreviousPage() {
    this.onPreviousPageEmit.emit(this.currentPage--);
  }

  onNextPage() {
    this.onNextPageEmit.emit(this.currentPage++);
  }

  objectKeys(object: any) {
    return Object.keys(object);
  }

  onCellClicked(key: any, value: any) {
    let header = this.table.headers.find(header => header.key === key);
    if (header && header.onClick) {
      header.onClick(value);
    }
  }

  getClassForCircle(controlStatus): string {
    return `circle-${controlStatus}`;
  }

  isFilterEmpty(): boolean {
    return Object.values(this.filter).every(value => value === '' || value === null);
  }

}
