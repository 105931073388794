import { createAction } from '@ngrx/store';
import { ACTION_HIDE_LOADING, ACTION_SHOW_LOADING } from '../../data-types/actions.enum';

export const showLoading = createAction(
  ACTION_SHOW_LOADING
)

export const hideLoading = createAction(
  ACTION_HIDE_LOADING
)
