import { createAction, props } from '@ngrx/store';
import { ACTION_EXPORT_STATISTICS_CONFIRM, ACTION_EXPORT_STATISTICS_NATIONALITY, ACTION_EXPORT_STATISTICS_NUMBER_OF_USERS, ACTION_EXPORT_STATISTICS_SUCCESS, ACTION_EXPORT_STATISTICS_TIME, ACTION_EXPORT_STATISTICS_TYPES_OF_USERS } from '../../data-types/actions.enum';



export const exportStatistics0Action = createAction(
    ACTION_EXPORT_STATISTICS_NUMBER_OF_USERS,
    props<{ statistics: any }>()
)

export const exportStatistics1Action = createAction(
    ACTION_EXPORT_STATISTICS_TIME,
    props<{ statistics: any }>()
)

export const exportStatistics2Action = createAction(
    ACTION_EXPORT_STATISTICS_NATIONALITY,
    props<{ statistics: any }>()
)

export const exportStatistics3Action = createAction(
    ACTION_EXPORT_STATISTICS_TYPES_OF_USERS,
    props<{ statistics: any }>()
)
export const exportStatisticsConfirmAction = createAction(
    ACTION_EXPORT_STATISTICS_CONFIRM,
    props<{ csvContent: string, fileName: string }>()
)
export const exporStatisticsSuccessAction = createAction(
    ACTION_EXPORT_STATISTICS_SUCCESS
)