<div class="actions" [ngClass]="{ 'alarmActivated': alarmActivated}">
  <ng-container *ngIf="typeCompoent === roleClient ">
    <div class="action-clear" (click)="onClearClientToStation()">
      <img src="assets/img/icons/clear-client-to-station.svg">
    </div>
  </ng-container>
  <ng-container *ngIf="typeCompoent !== roleClient ">
    <div *ngIf="allowsExport" class="action edit" (click)="onExportClick()">
      <img src="assets/img/icons/export.svg">
    </div>
    <div *ngIf="isClient" class="action delete" (click)="onEditClick()">
      <img src="assets/img/icons/ic-eye-open-grey.svg">
    </div>
    <ng-container *ngIf="!isClient">
      <div class="action edit" (click)="onEditClick()">
        <img src="assets/img/icons/edit.svg">
      </div>
      <div class="action delete" (click)="onDeleteClick()">
        <img src="assets/img/icons/delete.svg">
      </div>
    </ng-container>
  </ng-container>
</div>