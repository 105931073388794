import { IBoxState } from "../boxes/boxes.state"

export interface IBoxIncidenceState {
  alarmText: string,
  box: IBoxState,
  date: Date,
  stationId: number
}

export interface IBoxIncidencesListState {
  incidences: IBoxIncidenceState[],
  totalPages: number,
  totalElements: number,
  isLoading: boolean,
  isError: boolean
}

export const initialBoxIncidencesListState: IBoxIncidencesListState = {
  totalElements: 0,
  totalPages: 1,
  incidences: [],
  isLoading: true,
  isError: false

}
