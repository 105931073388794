import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackofficeSwitchComponent } from './backoffice-switch.component';
import { SharedModule } from '../../shared.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';



@NgModule({
  declarations: [
    BackofficeSwitchComponent
  ],
  imports: [
    SharedModule,
    MatSlideToggleModule
  ],
  exports: [
    BackofficeSwitchComponent
  ]
})
export class BackofficeSwitchModule { }
