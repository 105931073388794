import { createAction, props } from '@ngrx/store';
import { ACTION_HIDE_MODAL, ACTION_HIDE_MODAL_SUCCESS, ACTION_OPEN_MODAL, ACTION_OPEN_MODAL_SUCCESS, ACTION_SHOW_DIALOG_MODAL, ACTION_SHOW_INFO_MODAL } from '../../data-types/actions.enum';

export const showDialogAction = createAction(
  ACTION_SHOW_DIALOG_MODAL,
  props<{info: any}>()
)

export const showInfoModalAction = createAction(
  ACTION_SHOW_INFO_MODAL,
  props<{info: any}>()
)

export const openModalAction = createAction(
  ACTION_OPEN_MODAL,
  props<{modalId: string}>()
)
export const openModalSuccessAction = createAction(
  ACTION_OPEN_MODAL_SUCCESS
)

export const hideModalAction = createAction(
  ACTION_HIDE_MODAL
)
export const hideModalSuccessAction = createAction(
  ACTION_HIDE_MODAL_SUCCESS
)

