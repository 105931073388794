import { createAction, props } from "@ngrx/store";
import {
  ACTION_GET_ALARM_INCIDENCES,
  ACTION_GET_ALARM_INCIDENCES_SUCCESS,
  ACTION_GET_CHARGE_INCIDENCES,
  ACTION_GET_CHARGE_INCIDENCES_SUCCESS,
  ACTION_GET_DOOR_INCIDENCES,
  ACTION_GET_DOOR_INCIDENCES_SUCCESS,
  ACTION_GET_INCIDENCES_ALARM_FAILURE,
  ACTION_GET_INCIDENCES_CHARGE_FAILURE,
  ACTION_GET_INCIDENCES_DOOR_FAILURE,
} from "../../data-types/actions.enum";
import { IBoxIncidencesListState } from "./box-incidences.state";

export const getAlarmIncidencesAction = createAction(
  ACTION_GET_ALARM_INCIDENCES,
  props<{ queryFilter: string }>()
)

export const getAlarmIncidencesSuccessAction = createAction(
  ACTION_GET_ALARM_INCIDENCES_SUCCESS,
  props<{ incidencesList: IBoxIncidencesListState }>()
)

export const getDoorIncidencesAction = createAction(
  ACTION_GET_DOOR_INCIDENCES,
  props<{ queryFilter: string }>()
)

export const getDoorIncidencesSuccessAction = createAction(
  ACTION_GET_DOOR_INCIDENCES_SUCCESS,
  props<{ incidencesList: IBoxIncidencesListState }>()
)

export const getChargeIncidencesAction = createAction(
  ACTION_GET_CHARGE_INCIDENCES,
  props<{ queryFilter: string }>()
)

export const getChargeIncidencesSuccessAction = createAction(
  ACTION_GET_CHARGE_INCIDENCES_SUCCESS,
  props<{ incidencesList: IBoxIncidencesListState }>()
)

export const getIncidencesAlarmFailureAction = createAction(
  ACTION_GET_INCIDENCES_ALARM_FAILURE,
  props<{ error: boolean }>()
);
export const getIncidenceDoorsFailureAction = createAction(
  ACTION_GET_INCIDENCES_DOOR_FAILURE,
  props<{ error: boolean }>()
);
export const getIncidencesChargeFailureAction = createAction(
  ACTION_GET_INCIDENCES_CHARGE_FAILURE,
  props<{ error: boolean }>()
);
