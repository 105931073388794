import { Action, createReducer, on } from "@ngrx/store";
import { getAlarmIncidencesSuccessAction, getChargeIncidencesSuccessAction, getDoorIncidencesSuccessAction, getIncidenceDoorsFailureAction, getIncidencesAlarmFailureAction, getIncidencesChargeFailureAction } from "./box-incidences.actions";
import { IBoxIncidencesListState, initialBoxIncidencesListState } from "./box-incidences.state";

export const alarmIncidencesReducer = createReducer(
  initialBoxIncidencesListState,
  on(getAlarmIncidencesSuccessAction, (state: IBoxIncidencesListState, { incidencesList }) => {
    return ({
      ...state,
      isLoading: false,
      totalElements: incidencesList.totalElements,
      totalPages: incidencesList.totalPages,
      incidences: incidencesList.incidences
    })
  }), on(getIncidencesAlarmFailureAction, (state: IBoxIncidencesListState, { error }) => {
    return ({
      ...state,
      isError: error
    })
  })

)

export function alarmIncidencesReducers(state: IBoxIncidencesListState = initialBoxIncidencesListState, action: Action) {
  return alarmIncidencesReducer(state, action)
}

export const doorIncidencesReducer = createReducer(
  initialBoxIncidencesListState,
  on(getDoorIncidencesSuccessAction, (state: IBoxIncidencesListState, { incidencesList }) => {
    return ({
      ...state,
      isLoading: false,
      totalElements: incidencesList.totalElements,
      totalPages: incidencesList.totalPages,
      incidences: incidencesList.incidences
    })
  }),
  on(getIncidenceDoorsFailureAction, (state: IBoxIncidencesListState, { error }) => {
    return ({
      ...state,
      isError: error
    })
  })
)

export function doorIncidencesReducers(state: IBoxIncidencesListState = initialBoxIncidencesListState, action: Action) {
  return doorIncidencesReducer(state, action)
}

export const chargeIncidencesReducer = createReducer(
  initialBoxIncidencesListState,
  on(getChargeIncidencesSuccessAction, (state: IBoxIncidencesListState, { incidencesList }) => {
    return ({
      ...state,
      isLoading: false,
      totalElements: incidencesList.totalElements,
      totalPages: incidencesList.totalPages,
      incidences: incidencesList.incidences
    })
  }),

  on(getIncidencesChargeFailureAction, (state: IBoxIncidencesListState, { error }) => {
    return ({
      ...state,
      isError: error
    })
  })

)

export function chargeIncidencesReducers(state: IBoxIncidencesListState = initialBoxIncidencesListState, action: Action) {
  return chargeIncidencesReducer(state, action)
}
