import { createAction, props } from "@ngrx/store";
import { ACTION_CREATE_STATION, ACTION_CREATE_STATION_SUCCESS, ACTION_DELETE_STATION, ACTION_DELETE_STATION_CONFIRM, ACTION_DELETE_STATION_SUCCESS, ACTION_EDIT_SELECT_STATION, ACTION_EDIT_STATION, ACTION_EDIT_STATION_SUCCESS, ACTION_EXPORT_STATIONS, ACTION_EXPORT_STATIONS_CONFIRM, ACTION_GET_STATIONS, ACTION_GET_STATIONS_SUCCESS, ACTION_UPDATE_BOX_SELECTED_STATION } from "../../data-types/actions.enum";
import { IStation, IStationsListState } from "./stations.state";

export const getStationsAction = createAction(
  ACTION_GET_STATIONS,
  props<{ queryFilters: string }>()
)

export const getStationsSuccessAction = createAction(
  ACTION_GET_STATIONS_SUCCESS,
  props<{ stationsList: IStationsListState }>()
)

export const createStationAction = createAction(
  ACTION_CREATE_STATION,
  props<{ body: any }>()
)
export const createStationSuccessAction = createAction(
  ACTION_CREATE_STATION_SUCCESS
)

export const editSelectStationAction = createAction(
  ACTION_EDIT_SELECT_STATION,
  props<{ station: IStation | null }>()
)
export const editStationAction = createAction(
  ACTION_EDIT_STATION,
  props<{ body: any, id: number }>()
)
export const editStationSuccessAction = createAction(
  ACTION_EDIT_STATION_SUCCESS
)
export const updateBoxSelectedStationAction = createAction(
  ACTION_UPDATE_BOX_SELECTED_STATION,
  props<{ box: any }>()
)

export const deleteStationAction = createAction(
  ACTION_DELETE_STATION,
  props<{ station: IStation }>()
)
export const deleteStationConfirmAction = createAction(
  ACTION_DELETE_STATION_CONFIRM,
  props<{ stationId: number }>()
)
export const deleteStationSuccessAction = createAction(
  ACTION_DELETE_STATION_SUCCESS
)
export const exportStationsAction = createAction(
  ACTION_EXPORT_STATIONS,
  props<{ stations: IStation[] }>()

)
export const exportStationsConfirmAction = createAction(
  ACTION_EXPORT_STATIONS_CONFIRM,
  props<{ csvContent: string, fileName: string }>()

)


