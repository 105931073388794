import {Injectable} from '@angular/core';

import {BehaviorSubject, Observable} from 'rxjs';
import { BackofficeModalComponent } from '../components/backoffice-modal/backoffice-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private displays!: Array<BehaviorSubject<'open' | 'close'>>;
  private modals: Array<BackofficeModalComponent>;

  constructor() {
    this.modals = [];
    this.displays = [];
  }

  findModal(modalId: string): number {
    for (let [index, modal] of this.modals.entries()) {
      if (modal.modalId === modalId) {
        return index;
      }
    }
    return -1;
  }

  watch(newModal: BackofficeModalComponent): Observable<'open' | 'close'> {
    let modalIndex = this.findModal(newModal.modalId);
    if (modalIndex == -1) {
      this.modals.push(newModal);
      this.displays.push(new BehaviorSubject<'open' | 'close'>('close'));
      modalIndex = this.modals.length - 1;
    }
    return this.displays[modalIndex].asObservable();
  }

  open(modalId: string) {
    let modalIndex = this.findModal(modalId);
    if (modalIndex != -1) {
      this.displays[modalIndex].next('open');
    }
  }

  close(modalId: string) {
    let modalIndex = this.findModal(modalId);
    if (modalIndex != -1) {
      this.displays[modalIndex].next('close');
    }
  }

  closeAll(): void {
    for (let i = 0; i < this.modals.length; i++) {
      this.displays[i].next('close');
    }
  }
}
