<ng-container *ngIf="display$ | async as display">
  <section [class.open]="display === 'open'"
           (click)="close()">
    <div (click)="$event.stopPropagation()">
      <button class="close"
        type="button"
        (click)="close()">
        <img class="closeImg"
        src="assets/img/icons/icon-close-popup.svg"
        alt="icon">
      </button>
      <div *ngIf="icon" class="icon-container">
        <img [src]="icon">
      </div>
      <span class="title"> {{ title }}</span>
      <span *ngIf="message" class="subtitle"> {{ message }} </span>
      <div *ngIf="switchText" class="row">
        {{switchText}}
        <backoffice-switch
          [checked]="toggle"
          (onChangeToggle)="onActiveToggle($event)">
        </backoffice-switch>
      </div>
      <div class = "modal-buttons">

        <backoffice-button class="modal-button"
        *ngIf="noLabel"
        [label]="noLabel"
        [style]="buttonStyles.WIDE_SECONDARY"
        (onClick)="onNoClick($event)"
        ></backoffice-button>
        <backoffice-button class="modal-button"
        *ngIf="okLabel"
        [label]="okLabel"
        [style]="buttonStyles.WIDE"
        (onClick)="onOkClick($event)"
        ></backoffice-button>
      </div>

    </div>
  </section>
</ng-container>
