import { createAction, props } from '@ngrx/store';
import { ACTION_CREATE_USER, ACTION_CREATE_USER_SUCCESS, ACTION_DELETE_USER, ACTION_DELETE_USER_CONFIRM, ACTION_DELETE_USER_SUCCESS, ACTION_EDIT_SELECT_USER, ACTION_EDIT_USER, ACTION_EDIT_USER_SUCCESS, ACTION_EXPORT_USERS, ACTION_EXPORT_USERS_CONFIRM, ACTION_EXPORT_USERS_SUCCESS, ACTION_GET_USERS, ACTION_GET_USERS_SUCCESS } from '../../data-types/actions.enum';
import { IUser, IUsersListState } from './users.state';

export const getUsersAction = createAction(
  ACTION_GET_USERS,
  props<{queryFilters: string}>()
)
export const getUsersSuccessAction = createAction(
  ACTION_GET_USERS_SUCCESS,
  props<{usersList: IUsersListState}>()
)

export const createUserAction = createAction(
  ACTION_CREATE_USER,
  props<{body: any}>()
)
export const createUserSuccessAction = createAction(
  ACTION_CREATE_USER_SUCCESS
)

export const deleteUserAction = createAction(
  ACTION_DELETE_USER,
  props<{user: IUser}>()
)
export const deleteUserConfirmAction = createAction(
  ACTION_DELETE_USER_CONFIRM,
  props<{userId: number}>()
)
export const deleteUserSuccessAction = createAction(
  ACTION_DELETE_USER_SUCCESS
)

export const editSelectUserAction = createAction(
  ACTION_EDIT_SELECT_USER,
  props<{user: IUser | null}>()
)
export const editUserAction = createAction(
  ACTION_EDIT_USER,
  props<{body: any, userId: number}>()
)
export const editUserSuccessAction = createAction(
  ACTION_EDIT_USER_SUCCESS
)

export const exportUsersAction = createAction(
  ACTION_EXPORT_USERS,
  props<{users: IUser[]}>()
)
export const exportUsersConfirmAction = createAction(
  ACTION_EXPORT_USERS_CONFIRM,
  props<{csvContent: string, fileName: string}>()
)
export const exportUsersSuccessAction =  createAction(
  ACTION_EXPORT_USERS_SUCCESS
)
