import { AuthEffects } from "./auth/auth.effects";
import { BookingIncidencesEffects } from "./booking-incidences/booking-incidences.effects";
import { IncidencesEffects } from "./box-incidences/box-incidences.effects";
import { BoxesEffects } from "./boxes/boxes.effects";
import { ModalEffects } from "./modal/modal.effects";
import { StationsEffects } from "./stations/stations.effects";
import { UsersEffects } from "./users/users.effects";
import { ClientsEffects } from "./clients/clients.effects";
import { StatisticsEffects } from "./statistics/statistics.effects";

export const appEffects = [
  AuthEffects,
  UsersEffects,
  ModalEffects,
  StationsEffects,
  BoxesEffects,
  IncidencesEffects,
  BookingIncidencesEffects,
  ClientsEffects,
  StatisticsEffects
]
