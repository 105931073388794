<div class="search-stations">
    <div class="input-container" #searchStationContainer>
        <div class="input-wrap">
            <backoffice-input [placeholder]="'STATISTICS.SELECT_STATIONS.SEARCH_STATIONS' | translate"
                (onChange)="onInputChange($event) " (onInputIsFocus)="onInputIsFocus($event)"></backoffice-input>
            <img src=" assets/img/icons/backoffice-search.svg" alt="">
        </div>
        <div class="select-stations" *ngIf="showDropDown">
            <ng-container *ngFor="let station of stationSearched">
                <span class="station">
                    <img src="assets/img/icons/icon-plus.svg" alt="" (click)="onSelectStation(station , $event)">
                    {{station.stationId}}
                </span>
            </ng-container>
        </div>
    </div>
    <div class="select-all">
        <mat-checkbox (change)="onCheckboxChange($event)">
            {{'STATISTICS.SELECT_STATIONS.SELECT_ALL' |
            translate}}</mat-checkbox>
    </div>
    <div class="selected-stations" *ngIf="stationSelected.length > 0"
        [ngClass]="{'disabledDeselect' : disabledDeselect}">
        <ng-container *ngFor="let station of stationSelected">
            <span class="station">
                <img src="assets/img/icons/icon-minus.svg" alt="" (click)="onDeselectStation(station)">
                {{station.stationId}}
            </span> </ng-container>
    </div>
</div>