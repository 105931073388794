export interface IModalState {
  show: boolean,
  modalId: string,
  title: string,
  message: string,
  messageParams: string,
  switchText: string,
  icon: string,
  okLabel: string,
  cancelLabel: string,
  actionType: string,
  params: any,
}

export const initialModalState: IModalState = {
  show: false,
  modalId: "",
  title: "",
  message: "",
  messageParams: "",
  switchText: "",
  icon: "",
  okLabel: "",
  cancelLabel: "",
  actionType: "",
  params: null
}
