import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js/auto';

@Component({
  selector: 'backoffice-statics-doughnut',
  templateUrl: './backoffice-statistics-doughnut.html',
  styleUrls: ['./backoffice-statistics-doughnut.scss']
})
export class BackofficeStaticsDoughnutComponent implements AfterViewInit, OnChanges {

  constructor(private translate: TranslateService) {
    this.labelTitleSub = this.translate.instant('STATISTICS.SUBSCRIPTION');
  }


  public chart: any;
  @Input() id: string
  @Input() dataset2: any
  @Input() dataset3: any
  labelTitleSub: string



  ngAfterViewInit(): void {
    this.createChart()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataset2 && !changes.dataset2.firstChange) {
      if (changes.dataset2.currentValue.length > 0) {
        this.updateChart(this.chart, changes.dataset2.currentValue.map(item => item.key), changes.dataset2.currentValue.map(item => item.value));
      } else {
        this.updateChart(this.chart, [], []);
      }
    }

    if (changes.dataset3 && !changes.dataset3.firstChange) {
      if (changes.dataset3.currentValue.length > 0) {
        this.updateChart(this.chart, changes.dataset3.currentValue.map(item => item.key), changes.dataset3.currentValue.map(item => item.value));
      } else {
        this.updateChart(this.chart, [], []);
      }
    }
  }

  createChart() {
    const canvas = document.getElementById(this.id) as HTMLCanvasElement;
    const data = {
      labels: [],
      datasets: [{
        data: [],
        hoverOffset: 4
      }]
    };

    const options = {
      maintainAspectRatio: false,
      plugins: {
        colors: {
          forceOverride: true
        },
        legend: {
          display: false
        },
      }
    };

    const labelTitleSub = this.labelTitleSub;


    const innerLabel = {
      id: 'innerLabel',
      afterDatasetDraw(chart, args, pluginOptions) {
        const { ctx } = chart;
        const meta = args.meta;
        const xCoor = meta.data[0].x;
        const yCoor = meta.data[0].y;
        const perc = chart.data.datasets[0].data[1] / meta.total * 100;
        ctx.save();
        if (perc) {
          ctx.textAlign = 'center';
          const title = labelTitleSub
          ctx.font = 'bold 0.7rem  sans-serif';
          ctx.fillText(title, xCoor, yCoor);
          ctx.font = '0.8rem sans-serif';
          ctx.fillText(perc.toFixed(2) + '%', xCoor, yCoor + 20);
        }

        ctx.restore();
      },
    };


    this.chart = new Chart(canvas, {
      plugins: this.id == 'doughnutChart3' && [innerLabel],
      type: 'doughnut',
      data: data,
      options: options

    });

  }

  updateChart(chart: Chart, labels: string[], newData: number[]): void {
    chart.data.labels = [];
    chart.data.datasets.forEach((dataset) => {
      dataset.data = [];
    });

    chart.data.labels.push(...labels);
    chart.data.datasets.forEach((dataset) => {
      dataset.data.push(...newData);
    });



    chart.update();
  }

}
