import { createSelector } from "@ngrx/store";
import { IAppState } from "../app.state";
import { IBoxIncidencesListState } from "./box-incidences.state";

const selectAlarmIncidences = (state: IAppState) => state.alarmIncidences;
const selectDoorIncidences = (state: IAppState) => state.doorIncidences;
const selectChargeIncidences = (state: IAppState) => state.chargeIncidences;


export const selectAlarmIncidencesListState = createSelector(
  selectAlarmIncidences,
  (state: IBoxIncidencesListState) => state.incidences
)

export const selectAlarmIncidencesTotalPages = createSelector(
  selectAlarmIncidences,
  (state: IBoxIncidencesListState) => state.totalPages
)

export const selectDoorIncidencesListState = createSelector(
  selectDoorIncidences,
  (state: IBoxIncidencesListState) => state.incidences
)

export const selectDoorIncidencesTotalPages = createSelector(
  selectDoorIncidences,
  (state: IBoxIncidencesListState) => state.totalPages
)

export const selectChargeIncidencesListState = createSelector(
  selectChargeIncidences,
  (state: IBoxIncidencesListState) => state.incidences
)

export const selectChargeIncidencesTotalPages = createSelector(
  selectChargeIncidences,
  (state: IBoxIncidencesListState) => state.totalPages
)
export const selectAlarmIncidencesError = createSelector(
  selectAlarmIncidences,
  (state: IBoxIncidencesListState) => state.isError
);

export const selectDoorIncidencesError = createSelector(
  selectDoorIncidences,
  (state: IBoxIncidencesListState) => state.isError
);

export const selectChargeIncidencesError = createSelector(
  selectChargeIncidences,
  (state: IBoxIncidencesListState) => state.isError
);
