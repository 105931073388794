import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'backoffice-table-pagination',
  templateUrl: './backoffice-table-pagination.component.html',
  styleUrls: ['./backoffice-table-pagination.component.scss']
})
export class BackofficeTablePaginationComponent implements OnInit {

  @Input() totalPages: number = 0;
  @Input() currentPage: number = 0;

  @Output() onPrevious: EventEmitter<any> = new EventEmitter();
  @Output() onNext: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onPreviousPage() {
    this.onPrevious.emit();
  }

  onNextPage() {
    this.onNext.emit();
  }

}
