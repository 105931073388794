export interface IUsersListState {
  totalElements: number,
  totalPages: number,
  users: IUser[],
  selectedUser: IUser | null
}

export interface IUser {
  banned: boolean,
  createdDate: Date,
  email: string,
  id: number,
  name: string,
  picture: string,
  surname: string
  subscription: string,
  currentPeriodStart: Date,
  currentPeriodEnd: Date
}

export const initialUsersListState: IUsersListState = {
  totalElements: 0,
  totalPages: 1,
  users: [],
  selectedUser: null
}
