import { createSelector } from '@ngrx/store';
import { IAppState } from "../app.state";
import { IAuthState } from "./auth.state";

const selectAuth = (state: IAppState) => state.auth;

export const selectAuthState = createSelector(
  selectAuth,
  (state: IAuthState) => state
);

export const selectAuthStateError = createSelector(
  selectAuth,
  (state: IAuthState) => state.error
);
