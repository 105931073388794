import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared.module";
import { BackofficeButtonComponent } from "./backoffice-button.component";

@NgModule({
  declarations: [
    BackofficeButtonComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    BackofficeButtonComponent
  ]
})
export class BackofficeButtonModule { }
