import { createSelector } from "@ngrx/store";
import { IAppState } from "../app.state";
import { IBookingIncidencesListState } from "./booking-incidences.state";

const selectBookingIncidences = (state: IAppState) => state.bookingIncidences;

export const selectBookingIncidencesListState = createSelector(
  selectBookingIncidences,
  (state: IBookingIncidencesListState) => state.bookings
)

export const selectBookingIncidencesTotalPages = createSelector(
  selectBookingIncidences,
  (state: IBookingIncidencesListState) => state.totalPages
)
