import { createSelector } from '@ngrx/store';
import { IAppState } from "../app.state";
import { IModalState } from './modal.state';

const selectModal = (state: IAppState) => state.modal

export const selectModalState = createSelector(
  selectModal,
  (state: IModalState) => state
)

export const selectShowModalState = createSelector(
  selectModal,
  (state: IModalState) => state.show
)
