import { ChangeDetectorRef, Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { APP_LANGUAGES, GLOBAL_MODAL_ID } from './shared/constants';
import { EStorageType } from './shared/data-types/storage.enum';
import { ILoadingState } from './shared/store/loading/loading.state';
import { Store } from '@ngrx/store';
import { IAppState } from './shared/store/app.state';
import { selectLoadingState } from './shared/store/loading/loading.selector';
import { IModalState, initialModalState } from './shared/store/modal/modal.state';
import { selectModalState, selectShowModalState } from './shared/store/modal/modal.selector';
import { ACTION_CREATE_CLIENTS, ACTION_CREATE_STATION, ACTION_CREATE_USER, ACTION_DELETE_CLIENTS, ACTION_DELETE_CLIENTS_SUCCESS, ACTION_DELETE_STATION, ACTION_DELETE_STATION_SUCCESS, ACTION_DELETE_USER, ACTION_DELETE_USER_SUCCESS, ACTION_EDIT_CLIENTS, ACTION_EDIT_STATION, ACTION_EDIT_USER, ACTION_EXPORT_CLIENTS, ACTION_EXPORT_CLIENTS_CONFIRM, ACTION_EXPORT_STATIONS, ACTION_EXPORT_STATISTICS_CONFIRM, ACTION_EXPORT_STATISTICS_NATIONALITY, ACTION_EXPORT_STATISTICS_NUMBER_OF_USERS, ACTION_EXPORT_STATISTICS_TIME, ACTION_EXPORT_STATISTICS_TYPES_OF_USERS, ACTION_EXPORT_USERS, ACTION_EXPORT_USERS_CONFIRM, ACTION_FINISH_BOOKING, ACTION_GET_STATIONS_TYPE, ACTION_LOGOUT, ACTION_OPEN_BOX, ACTION_RESET_STATION, } from './shared/data-types/actions.enum';
import { logoutConfirmAction } from './shared/store/auth/auth.actions';
import { createUserSuccessAction, deleteUserConfirmAction, deleteUserSuccessAction, editUserSuccessAction, exportUsersConfirmAction } from './shared/store/users/users.actions';
import { hideModalAction, openModalAction } from './shared/store/modal/modal.actions';
import { hideLoading, showLoading } from './shared/store/loading/loading.actions';
import { createStationSuccessAction, deleteStationConfirmAction, deleteStationSuccessAction, editStationSuccessAction, exportStationsConfirmAction } from './shared/store/stations/stations.actions';
import { finishBookingConfirmAction, getStationType, openBoxConfirmAction, resetStationActionConfim } from './shared/store/boxes/boxes.actions';
import { createClientsSuccessAction, deleteClientsConfirmAction, deleteClientsSuccessAction, editClientsSuccessAction, exportClientsConfirmAction } from './shared/store/clients/clients.actions';
import { exportStatisticsConfirmAction } from './shared/store/statistics/statistics.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public showHeader: boolean = true;
  public showMenu: boolean = true;
  public isLoading!: boolean;

  globalModalId: string = GLOBAL_MODAL_ID;
  globalModalInfo: IModalState = initialModalState;

  loading$: Observable<ILoadingState> = this.store.select(selectLoadingState);
  modal$ = this.store.select(selectModalState);
  modalShow$ = this.store.select(selectShowModalState);

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private router: Router,
    private store: Store<IAppState>,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.initLanguage();
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (val.urlAfterRedirects == '/login') {
          this.showHeader = false;
          this.showMenu = false;
        } else {
          this.store.dispatch(getStationType())
          this.showHeader = true;
          this.showMenu = true;
        }
      }
    });

    this.loading$.pipe(takeUntil(this.destroy$)).subscribe(loading => {
      this.isLoading = loading.show;
      this.cdRef.detectChanges();
    })

  }

  ngAfterViewInit() {
    this.modal$.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.globalModalInfo = info;
      if (info.show) {
        this.store.dispatch(openModalAction({ modalId: info.modalId }));
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private initLanguage() {
    this.translate.addLangs(APP_LANGUAGES);
    this.translate.setDefaultLang('ca');
    const lang = localStorage.getItem(EStorageType.Language);
    if (lang) {
      this.translate.use(lang);
    } else {
      this.translate.use('ca');
      localStorage.setItem(EStorageType.Language, 'ca');
    }
  }


  onOkCallback(event) {
    switch (this.globalModalInfo.actionType) {
      case ACTION_LOGOUT:
        this.store.dispatch(logoutConfirmAction());
        break;
      case ACTION_CREATE_USER:
        this.store.dispatch(createUserSuccessAction());
        break;
      case ACTION_EDIT_USER:
        this.store.dispatch(editUserSuccessAction());
        break;
      case ACTION_DELETE_USER:
        this.store.dispatch(deleteUserConfirmAction({ userId: this.globalModalInfo.params.id }));
        break;
      case ACTION_DELETE_USER_SUCCESS:
        this.store.dispatch(deleteUserSuccessAction());
        break;
      case ACTION_CREATE_CLIENTS:
        this.store.dispatch(createClientsSuccessAction());
        break;
      case ACTION_EDIT_CLIENTS:
        this.store.dispatch(editClientsSuccessAction());
        break;
      case ACTION_DELETE_CLIENTS:
        this.store.dispatch(deleteClientsConfirmAction({ userId: this.globalModalInfo.params.id }));
        break;
      case ACTION_DELETE_CLIENTS_SUCCESS:
        this.store.dispatch(deleteClientsSuccessAction());
        break;
      case ACTION_DELETE_STATION:
        this.store.dispatch(deleteStationConfirmAction({ stationId: this.globalModalInfo.params.id }));
        break;
      case ACTION_DELETE_STATION_SUCCESS:
        this.store.dispatch(deleteStationSuccessAction());
        break;
      case ACTION_EXPORT_STATIONS:
        this.store.dispatch(exportStationsConfirmAction({ csvContent: this.globalModalInfo.params, fileName: "Stations" }))
        break;
      case ACTION_EXPORT_CLIENTS:
        this.store.dispatch(exportClientsConfirmAction({ csvContent: this.globalModalInfo.params, fileName: "Clients" }))
        break;
      case ACTION_EXPORT_USERS:
        this.store.dispatch(exportUsersConfirmAction({ csvContent: this.globalModalInfo.params, fileName: "Usuaris" }))
        break;
      case ACTION_EXPORT_STATISTICS_NUMBER_OF_USERS:
        this.store.dispatch(exportStatisticsConfirmAction({ csvContent: this.globalModalInfo.params, fileName: "Numero-de-usuaris" }))
        break;
      case ACTION_EXPORT_STATISTICS_TIME:
        this.store.dispatch(exportStatisticsConfirmAction({ csvContent: this.globalModalInfo.params, fileName: "Tiempo-de-uso" }))
        break;
      case ACTION_EXPORT_STATISTICS_NATIONALITY:
        this.store.dispatch(exportStatisticsConfirmAction({ csvContent: this.globalModalInfo.params, fileName: "Nacionalidad" }))
        break;
      case ACTION_EXPORT_STATISTICS_TYPES_OF_USERS:
        this.store.dispatch(exportStatisticsConfirmAction({ csvContent: this.globalModalInfo.params, fileName: "Tipos-de-usuarios" }))
        break;
      case ACTION_CREATE_STATION:
        this.store.dispatch(createStationSuccessAction());
        break;
      case ACTION_EDIT_STATION:
        this.store.dispatch(editStationSuccessAction());
        break;

      case ACTION_OPEN_BOX:
        this.store.dispatch(openBoxConfirmAction({ stationId: this.globalModalInfo.params.stationId, boxId: this.globalModalInfo.params.boxId }));
        this.store.dispatch(showLoading())
        this.store.dispatch(hideModalAction());
        break;
      case ACTION_RESET_STATION:
        this.store.dispatch(resetStationActionConfim({ stationId: this.globalModalInfo.params.stationId, body: this.globalModalInfo.params.body }));
        this.store.dispatch(showLoading())
        this.store.dispatch(hideModalAction());
        break;
      case ACTION_GET_STATIONS_TYPE:
        this.store.dispatch(getStationType())
        break;
      case ACTION_FINISH_BOOKING:
        this.store.dispatch(finishBookingConfirmAction(
          {
            stationId: this.globalModalInfo.params.stationId,
            boxId: this.globalModalInfo.params.boxId,
            applyPenalty: event.toggle
          })
        );
        this.store.dispatch(showLoading())
        this.store.dispatch(hideModalAction());
        break;
      default:
        this.store.dispatch(hideModalAction());
        break;


    }
  }

  onCloseModal() {
    this.store.dispatch(hideModalAction());
  }

}
