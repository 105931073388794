import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EStorageType } from '../data-types/storage.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private API_URL = environment.API_URL;
  constructor(
    private http: HttpClient
  ) { }

  login(body: any): Observable<any> {
    return this.http.post(`${this.API_URL}/admin/login`, body)
  }

  getToken() {
    return localStorage.getItem(EStorageType.Token)
  }

  getRole() {
    const rol = localStorage.getItem(EStorageType.Rol)
    return JSON.parse(rol)
  }

  clearStorage() {
    localStorage.clear()
  }
  refreshToken(refreshToken: string): Observable<any> {

    return this.http.post(`${this.API_URL}/token/refresh`, { refreshToken })
  }
}
