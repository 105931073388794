import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { StationsService } from '../../services/stations.service';
import { finalize, switchMap, tap } from 'rxjs';


@Component({
  selector: 'backoffice-search-stations',
  templateUrl: './backoffice-search-stations.component.html',
  styleUrls: ['./backoffice-search-stations.scss']
})

export class SearchStationsComponent implements OnInit {


  public stationSearched = []
  public stationSelected = []
  public inputValue: string = ""
  public query = '?page=0&pageSize=15'
  public showDropDown: boolean = false
  public disabledDeselect: boolean = false
  @Output() stationsSelected: EventEmitter<any> = new EventEmitter();

  @ViewChild('searchStationContainer') searchStationContainer: ElementRef;

  constructor(
    private _stationsService: StationsService,
  ) {
  }



  ngOnInit(): void {

  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.searchStationContainer.nativeElement.contains(event.target)) {
      this.showDropDown = false
    }
  }

  onCheckboxChange($event: MatCheckboxChange) {
    if ($event.checked) {
      this.stationSelected = []
      this._stationsService.getStations(this.query).subscribe(
        (response) => {
          if (response) {
            const stationsId = response.data.stations.map((station: any) => {
              this.stationSelected.push(station)
              return station.id
            })
            this.stationsSelected.emit(stationsId)
          }
        },
      );
      this.disabledDeselect = true
    } else {
      this.stationsSelected.emit([])
      this.stationSelected = []
      this.disabledDeselect = false
    }
  }

  searchClientService(query) {
    return this._stationsService.getStations(query).pipe(
      tap((response) => {
        if (response) {
          if (this.stationSelected.length > 0) {
            response.data.stations.filter((station) => {
              return !this.stationSelected.some((stationSelected) => stationSelected.stationId === station.stationId);
            });
          } else {
            this.stationSearched = response.data.stations
          }
        }
      }),
      finalize(() => {
        this.showDropDown = true
      })
    );
  }


  onInputIsFocus(event: any) {
    if (this.inputValue.length <= 0) {
      this.searchClientService(this.query).subscribe()
    }
  }


  onSelectStation(station: any, event: any) {
    event.stopPropagation();
    const serachFiltred = this.stationSearched.filter((stationSearched) => stationSearched.stationId !== station.stationId)
    this.stationSearched = serachFiltred
    this.stationSelected.push(station)
    const stationsId = this.stationSelected.map((stationSelected) => stationSelected.id)
    this.stationsSelected.emit(stationsId)

  }

  onDeselectStation(station: any) {
    const stationSelectedFiltred = this.stationSelected.filter((stationSelected) => stationSelected.stationId !== station.stationId)
    this.stationSelected = stationSelectedFiltred
    this.stationSearched.push(station)
    const stationsId = this.stationSelected.map((stationSelected) => stationSelected.id)
    this.stationsSelected.emit(stationsId)


  }

  onInputChange(event: any) {
    let queryId = `?stationId=${event}&page=0&pageSize=10`
    this.inputValue = event
    if (event.length <= 0) {
      this.searchClientService(this.query).subscribe()
    } else {
      this.searchClientService(queryId).subscribe()
    }
  }

}