import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { finalize, map, Observable, of, switchMap, catchError } from "rxjs";
import { EStorageType } from "../../data-types/storage.enum";
import { AuthService } from "../../services/auth.service";
import { loginAction, loginErrorAction, loginSuccessAction, logoutAction, logoutConfirmAction } from "./auth.actions";
import { Store } from '@ngrx/store';
import { hideLoading } from "../loading/loading.actions";
import { hideModalAction, showDialogAction } from "../modal/modal.actions";
import { GLOBAL_MODAL_ID } from "../../constants";

@Injectable()
export class AuthEffects {
  private _actions$ = this.injector.get(Actions);
  private _authService = this.injector.get(AuthService);
  private _router = this.injector.get(Router);
  private _store = this.injector.get(Store);

  constructor(
    private injector: Injector,
  ) { }

  login$ = createEffect(() =>
    this._actions$.pipe(
      ofType(loginAction),
      switchMap((action) => {
        return this._authService.login({
          email: action.email,
          password: action.password
        }).pipe(
          finalize(() => {
            this._store.dispatch(hideLoading())
          }),
          map((response) => {
            localStorage.setItem(EStorageType.Token, response.data.token);
            localStorage.setItem(EStorageType.User, JSON.stringify(response.data.username));
            localStorage.setItem(EStorageType.Rol, JSON.stringify(response.data.rol));
            if (response.data.rol == 'ADMIN') {
              this._router.navigate(['stations']);
            } else if (response.data.rol == 'CLIENT') {
              this._router.navigate(['stations']);
            }
            return loginSuccessAction({ user: response.data })
          }),
          catchError((error) => {
            return this.catchError(error)
          })
        )
      })
    )
  )

  logout$ = createEffect(() =>
    this._actions$.pipe(
      ofType(logoutAction),
      switchMap((action) => {
        return of(
          showDialogAction({
            info: {
              show: true,
              modalId: GLOBAL_MODAL_ID,
              title: 'LABELS.LOGOUT',
              message: 'LOGOUT.TITLE',
              okLabel: 'LABELS.YES',
              cancelLabel: 'LABELS.NO',
              type: action.type
            }
          })
        )
      })
    )
  )

  logoutConfirm$ = createEffect(() =>
    this._actions$.pipe(
      ofType(logoutConfirmAction),
      switchMap(() => {
        localStorage.clear();
        this._router.navigate(['login']);
        return of(
          hideModalAction()
        )
      })
    )
  )

  private catchError(response: HttpErrorResponse): Observable<any> {
    return of(
      loginErrorAction({
        error: {
          status: response.error.status,
          message: response.error.message || response.statusText
        }
      })
    );
  }

}
