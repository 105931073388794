import { createReducer, on, Action } from '@ngrx/store';
import { ILoadingState, initialLoadingState } from './loading.state';
import * as LoadingActions from '../loading/loading.actions'

export const loadingReducer = createReducer(
  initialLoadingState,
  on(LoadingActions.showLoading, (state: ILoadingState) => {
    return ({
      ...state,
      show: true
    })
  }),
  on(LoadingActions.hideLoading, (state: ILoadingState) => {
    return ({
      ...state,
      show: false
    })
  })
)

export function loadingReducers(state: ILoadingState = initialLoadingState, action: Action) {
  return loadingReducer(state, action);
}
