import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/shared/store/app.state';
import { exportStatistics0Action, exportStatistics1Action, exportStatistics2Action, exportStatistics3Action } from 'src/app/shared/store/statistics/statistics.actions';


@Component({
  selector: 'app-incidences',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StaticsComponent implements OnInit {

  dataSet0: any = []
  dataSet1: any = []
  dataSet2: any = []
  dataSet3: any = []

  public loadingMetrics = {
    metric0: false,
    metric1: false,
    metric2: false,
    metric3: false
  };


  smallerContainer: boolean = false

  constructor(

    private store: Store<IAppState>,
  ) {

  }

  ngOnInit(): void {



  }

  statisticsIsLoading(value) {
    this.loadingMetrics.metric0 = value.metric0
    this.loadingMetrics.metric1 = value.metric1
    this.loadingMetrics.metric2 = value.metric2
    this.loadingMetrics.metric3 = value.metric3
  }

  exportStatics0() {
    this.store.dispatch(exportStatistics0Action({ statistics: this.dataSet0 }));
  }
  exportStatics1() {
    this.store.dispatch(exportStatistics1Action({ statistics: this.dataSet1 }));
  }
  exportStatics2() {
    this.store.dispatch(exportStatistics2Action({ statistics: this.dataSet2 }));
  }
  exportStatics3() {
    this.store.dispatch(exportStatistics3Action({ statistics: this.dataSet3 }));
  }


  formatDataSet0(data: any) {
    this.dataSet0 = data
  }
  formatDataSet1(data: any) {


    this.dataSet1 = data
  }

  formatDataSet2(data: any) {
    this.dataSet2 = data

  }
  formatDataSet3(data: any) {

    this.dataSet3 = data
  }

  drawerOpened() {
    this.smallerContainer = !this.smallerContainer
  }
}
