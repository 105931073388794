<div class="menu-wrapper" *ngIf="userLogged">

  <div class="user">
    <div class="profile-photo"></div>
    <div class="infoUser">
      <span class="username">{{ user }}</span>
      <span class="role">{{ 'USER_ROLES.' + rol + '' | translate }}</span>
    </div>
  </div>
  <div class="line"></div>
  <ng-container *ngFor="let item of items; let i = index">
    <ng-container *ngIf="isClient && (item.id === 1 || item.id === 4 )">
      <span class="menu" [class.selected]="isItemSelected(i)" (click)="selectItem($event,i)">
        <img class="item-logo" [src]="isItemSelected(i) ? item.iconActive : item.iconInactive" />
        {{ item.text_key | translate }}
      </span>
    </ng-container>
    <ng-container *ngIf="!isClient">
      <span class="menu" [class.selected]="isItemSelected(i)" (click)="selectItem($event,i)">
        <img class="item-logo" [src]="isItemSelected(i) ? item.iconActive : item.iconInactive" />
        {{ item.text_key | translate }}
      </span>
    </ng-container>
  </ng-container>

</div>