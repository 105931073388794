import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EButtonStyles } from '../../data-types/button.enum';
import { ThemePalette } from '@angular/material/core';
import { GROUPING_STATICS, PAGE_SIZE_TABLES } from '../../constants';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, finalize, takeUntil } from 'rxjs';
import { selectStationsListStateStations } from '../../store/stations/stations.selector';
import { IAppState } from '../../store/app.state';
import { Store } from '@ngrx/store';
import { showLoading } from '../../store/loading/loading.actions';
import { getStationsAction } from '../../store/stations/stations.actions';
import { MetricsService } from '../../services/metrics.service';
import { ExportsService } from '../../services/exports.service';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'backoffice-select-statics',
  templateUrl: './backoffice-select-statistics.component.html',
  styleUrls: ['./backoffice-select-statistics.component.scss']
})





export class SelectStaticsComponent implements OnInit {


  public options = GROUPING_STATICS
  public EButtonStyles = EButtonStyles
  private destroy$: Subject<void> = new Subject<void>();
  public currentPage: number = 0;
  public stationsList: any[] = [];
  public form: FormGroup = new FormGroup({
    selectedOptions: new FormArray([], Validators.required),
    selectedDate: new FormControl([], Validators.required),
    stationSelected: new FormArray([], Validators.required),
  });
  stationsList$ = this.store.select(selectStationsListStateStations);
  public loadingMetrics = {
    metric0: true,
    metric1: true,
    metric2: true,
    metric3: true
  };
  @Output() statisticsLoading: EventEmitter<any> = new EventEmitter();
  @Output() showStaticsType0: EventEmitter<any> = new EventEmitter();
  @Output() showStaticsType1: EventEmitter<any> = new EventEmitter();
  @Output() showStaticsType2: EventEmitter<any> = new EventEmitter();
  @Output() showStaticsType3: EventEmitter<any> = new EventEmitter();

  exportMetric0: any = null;
  exportMetric1: any = null;
  exportMetric2: any = null;
  exportMetric3: any = null;



  constructor(
    private store: Store<IAppState>,
    private metricsService: MetricsService,
  ) { }

  ngOnInit(): void {
    this.getStations()
    this.stationsList$.pipe((takeUntil(this.destroy$))).subscribe((response: any) => {
      response.map((station) => {
        this.stationsList.push({
          id: station.id,
          stationName: station.stationId,
          postalCode: station.postalCode,
          city: station.city
        });
      })
    })

  }


  getStations() {
    this.store.dispatch(showLoading());
    this.store.dispatch(getStationsAction({ queryFilters: this.getQueryFilters() }));
  }

  getQueryFilters() {
    let query = `?page=${this.currentPage}&pageSize=${PAGE_SIZE_TABLES}`;
    return query;
  }

  onSelectChange(value: number) {
    this.options.forEach(option => {
      if (value == option.id) {
        option.isCheked = !option.isCheked
      }
    });
    const typUserSelected = this.form.get('selectedOptions') as FormArray;
    if (!this.form.get('selectedOptions')?.value.includes(value)) {
      typUserSelected.push(new FormControl(value));
    } else {
      const index = this.form.get('selectedOptions')?.value.indexOf(value);
      typUserSelected.removeAt(index);
    }
  }

  onCheckboxChange(event: any, option: string): void {
    const stationSelectedArray = this.form.get('stationSelected') as FormArray;
    if (event.checked) {
      stationSelectedArray.push(new FormControl(option));
    } else {
      const index = stationSelectedArray.controls.findIndex(x => x.value === option);
      stationSelectedArray.removeAt(index);
    }
  }
  onDateChange(date: Date) {
    this.form.get('selectedDate')?.setValue(date)

  }

  addStationSelected(stationSelected: any[]) {
    const stationSelectedArray = this.form.get('stationSelected') as FormArray;
    stationSelectedArray.clear();
    stationSelected.forEach(station => {
      stationSelectedArray.push(new FormControl(station));
    });
  }


  getMetrics() {
    this.statisticsLoading.emit({ metric0: true, metric1: true, metric2: true, metric3: true });
    const selectedDate = this.form.value.selectedDate;
    const fromDate = selectedDate.start.toISOString().split('T')[0];
    const untilDate = selectedDate.end.toISOString().split('T')[0];
    const selectedOptions = this.form.value.selectedOptions;
    const stationSelected = this.form.value.stationSelected;

    let query = `&startDate=${fromDate}&finishDate=${untilDate}`;
    if (selectedOptions) {
      query += `&subscriptionTypes=${selectedOptions}`;
    }
    if (stationSelected.length > 0) {
      query += `&stations=${stationSelected.join(',')}`;
    }

    this.metricsService.getMetricsType0(query).pipe(
      finalize(() => {
        this.loadingMetrics.metric0 = false;
        this.statisticsLoading.emit(this.loadingMetrics);

      })
    ).subscribe((response: any) => {
      this.exportMetric0 = response.data
      if (response.data.length > 0) {
        this.showStaticsType0.emit(response.data);
      } else {
        this.showStaticsType0.emit({ noData: true });
      }
    });

    this.metricsService.getMetricsType1(query).pipe(
      finalize(() => {
        this.loadingMetrics.metric1 = false;
        this.statisticsLoading.emit(this.loadingMetrics);
      })
    ).subscribe((response: any) => {
      this.exportMetric1 = response.data

      if (response.data.length > 0) {
        this.showStaticsType1.emit(response.data);
      } else {
        this.showStaticsType1.emit({ noData: true });
      }
    });

    this.metricsService.getMetricsType2(query).pipe(
      finalize(() => {
        this.loadingMetrics.metric2 = false;
        this.statisticsLoading.emit(this.loadingMetrics);
      })
    ).subscribe((response: any) => {
      this.exportMetric2 = response.data
      if (response.data.length > 0) {
        this.showStaticsType2.emit(response.data);

      } else {
        this.showStaticsType2.emit({ noData: true });
      }
    });


    this.metricsService.getMetricsType3(query).pipe(
      finalize(() => {
        this.loadingMetrics.metric3 = false;
        this.statisticsLoading.emit(this.loadingMetrics);
      })
    ).subscribe((response: any) => {
      this.exportMetric3 = response.data

      if (response.data.length > 0) {
        this.showStaticsType3.emit(response.data);
      } else {
        this.showStaticsType3.emit({ noData: true });
      }
    });
  }

}
