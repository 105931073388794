import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ERoleCMS } from '../../data-types/cms-roles';

@Component({
  selector: 'backoffice-table-actions',
  templateUrl: './backoffice-table-actions.component.html',
  styleUrls: ['./backoffice-table-actions.component.scss']
})
export class BackofficeTableActionsComponent implements OnInit {

  @Input() allowsExport: boolean = false;
  @Output() onExport: EventEmitter<any> = new EventEmitter();
  @Output() onClearClientToStationclick: EventEmitter<any> = new EventEmitter();
  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Input() typeCompoent: any;
  @Input() alarmActivated: boolean = false;
  roleClient: ERoleCMS = ERoleCMS.CLIENT;
  isClient: boolean = false;

  private _authService = this.injector.get(AuthService);


  constructor(
    private injector: Injector,
  ) { }



  ngOnInit(): void {
    this._authService.getRole() == ERoleCMS.CLIENT ? this.isClient = true : this.isClient = false
  }

  onExportClick() {
    this.onExport.emit();
  }

  onClearClientToStation() {
    this.onClearClientToStationclick.emit();

  }
  onEditClick() {
    this.onEdit.emit();
  }

  onDeleteClick() {
    this.onDelete.emit();
  }

}
