import { Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { EInputStyles } from '../../data-types/input.enum';

@Component({
  selector: 'backoffice-input',
  templateUrl: './backoffice-input.component.html',
  styleUrls: ['./backoffice-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BackofficeInputComponent),
      multi: true,
    },
  ],
})
export class BackofficeInputComponent implements ControlValueAccessor {

  @Input() value: any = "";
  @Input() label: string = "";
  @Input() type: string = "text";
  @Input() placeholder: string = "";
  @Input() clickable: boolean = false;
  @Input() inputStyle: string = EInputStyles.PRIMARY;
  @Input() isForm: boolean = false;
  @Input() control: FormControl = new FormControl();
  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() onKeyPress: any = null;
  @Input() isAlarmActive: boolean;
  @Input() maxRange: number = undefined
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() onInputIsFocus: EventEmitter<any> = new EventEmitter();
  @Input() exededTime: boolean

  showPassword: boolean = false;
  @ViewChild("input") input: ElementRef | undefined;
  private internalInputChange: any;


  public onInputChange($event?: any) {
    if ($event) {
      this.value = $event.target.value;
      if (this.isForm) {
        this.internalInputChange(this.value);
      } else {
        this.onChange.emit(this.value);
      }
    }
  }
  constructor() { }


  getAlarmBoxClass(): string {

    let alarmClass = '';

    if (this.type === 'alarmBox' && this.isAlarmActive) {
      alarmClass += 'alarm-active ';
    }
    if (this.type === 'alarmBox' && this.exededTime) {
      alarmClass += 'alarm-ocupation-active';
    }


    return alarmClass


  }

  writeValue(value: any): void {
    if (value) {
      this.value = value;
    }
  }
  registerOnChange(fn: any): void {
    this.internalInputChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onInputIsFocus.emit();
  }

  togglePwdVisibility() {
    this.showPassword = !this.showPassword;
  }

}
