import { ActionReducerMap } from '@ngrx/store';
import { IAppState } from './app.state';
import { authReducers } from './auth/auth.reducer';
import { modalReducers } from './modal/modal.reducer';
import { loadingReducers } from './loading/loading.reducer';
import { usersReducers } from './users/users.reducer';
import { stationsReducers } from './stations/stations.reducer';
import { boxesReducers } from './boxes/boxes.reducer';
import { clientsReducers } from './clients/clients.reducer';
import { alarmIncidencesReducers, chargeIncidencesReducers, doorIncidencesReducers } from './box-incidences/box-incidences.reducer';
import { bookingIncidencesReducers } from './booking-incidences/booking-incidences.reducer';

export const appReducers: ActionReducerMap<IAppState, any> = {
  loading: loadingReducers,
  auth: authReducers,
  users: usersReducers,
  modal: modalReducers,
  stations: stationsReducers,
  boxes: boxesReducers,
  alarmIncidences: alarmIncidencesReducers,
  doorIncidences: doorIncidencesReducers,
  chargeIncidences: chargeIncidencesReducers,
  bookingIncidences: bookingIncidencesReducers,
  clients: clientsReducers
}


