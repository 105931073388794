<div class="side-bar-statics">
    <div class="date-piker">
        <p>{{ ("STATISTICS.SELECT_DATE") | translate
            }}</p> <backoffice-date-picker (onChangeDate)="onDateChange($event)"></backoffice-date-picker>
    </div>
    <div class="type-user-cotainer">
        <p>{{ ("STATISTICS.TYPOLOGY_USER") | translate}}</p>
        <div class="user-type">
            <div *ngFor="let type of options" class="type-options" [ngClass]="{'active': type.isCheked}"
                (click)="onSelectChange(type.id)" translate>
                <span>
                    {{type.type| translate }}
                </span>
            </div>
        </div>
    </div>
    <div class="search-stations-container">
        <p>{{ ("STATISTICS.SELECT_STATION") | translate
            }}</p>
        <backoffice-search-stations (stationsSelected)="addStationSelected($event) ">
        </backoffice-search-stations>
    </div>
    <div class=" button-container">
        <div class="button-wrapper">
            <backoffice-button [disabled]="form.invalid" [style]="EButtonStyles.NARROW" (onClick)="getMetrics()"
                [label]="'Buscar estadisticas'"></backoffice-button>
        </div>
    </div>