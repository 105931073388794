export interface ITable {
  headers: IHeaders[],
  rows: any[]
}

export interface IHeaders {
  title: string,
  key: string,
  class: string,
  type: string,
  onClick?: any
}

export const initialTable = {
  headers: [],
  rows: []
}
