import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'backoffice-date-picker',
  templateUrl: './backoffice-date-picker.component.html',
  styleUrls: ['./backoffice-date-picker.component.scss']
})
export class BackofficeDatePickerComponent implements OnInit {

  @Input() initDate: Date | undefined = undefined;
  @Input() endDate: Date | undefined = undefined;
  @Input() placeholder: string = "";
  @Input() minDate: Date | undefined = undefined;
  @Input() maxDate: Date | undefined = undefined;
  @Input() disabled: boolean = false;
  @Output() onChangeDate: EventEmitter<any> = new EventEmitter();

  dateForm: FormGroup = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  constructor() { }

  ngOnInit(): void {

  }

  ngOnChanges(): void {
    this.dateForm = new FormGroup({
      start: new FormControl<Date | null>(this.initDate),
      end: new FormControl<Date | null>(this.endDate),
    });
  }

  onDateChange() {
    const startDate = this.dateForm.get('start')?.value;
    const endDate = this.dateForm.get('end')?.value;
    if (startDate && endDate) this.onChangeDate.emit({ start: startDate, end: endDate });
  }

}
