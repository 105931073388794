import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/login/login.component';
import { StationsComponent } from './modules/stations/stations.component';
import { UsersComponent } from './modules/users/users.component';
import { AuthGuardService } from './shared/services/auth-guard.service'
import { IncidencesComponent } from './modules/incidences/incidences.component';
import { ClientsComponent } from './modules/clients-file/clients/clients.component';
import { StaticsComponent } from './modules/statistics/statistics.component';
const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'users',
    component: UsersComponent,
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'stations',
    component: StationsComponent,
    loadChildren: () => import('./modules/stations/stations.module').then(m => m.StationsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'clients',
    component: ClientsComponent,
    loadChildren: () => import('./modules/clients-file/clients/clients.module').then(m => m.ClientsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'incidences',
    component: IncidencesComponent,
    loadChildren: () => import('./modules/incidences/incidences.module').then(m => m.IncidencesModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'statistics',
    component: StaticsComponent,
    loadChildren: () => import('./modules/statistics/statistics.module').then(m => m.StaticsModule),
    canActivate: [AuthGuardService]
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
