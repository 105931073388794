import { Injectable, Injector } from "@angular/core";
import { Store } from '@ngrx/store';
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { exportStatistics0Action, exportStatistics1Action, exportStatistics2Action, exportStatistics3Action, exportStatisticsConfirmAction, } from "./statistics.actions";
import { finalize, map, Observable, switchMap, catchError, of } from "rxjs";
import { ClientsService } from "../../services/clients.service";
import { hideLoading } from "../loading/loading.actions";
import { HttpErrorResponse } from "@angular/common/http";
import { hideModalAction, showDialogAction, showInfoModalAction } from "../modal/modal.actions";
import { GLOBAL_MODAL_ID, WARNING_ICON } from "../../constants";
import { Router } from "@angular/router";
import { ExportsService } from "../../services/exports.service";

@Injectable()
export class StatisticsEffects {
    private _actions$ = this.injector.get(Actions);
    private _exportService = this.injector.get(ExportsService);

    constructor(
        private injector: Injector,
    ) { }



    exportStatistics0$ = createEffect(() =>
        this._actions$.pipe(
            ofType(exportStatistics0Action),
            switchMap((action) => {
                return of(
                    showDialogAction({
                        info: {
                            modalId: GLOBAL_MODAL_ID,
                            title: 'STATISTICS.EXPORT.TITLE',
                            okLabel: 'LABELS.OK',
                            cancelLabel: 'LABELS.CANCEL',
                            type: action.type,
                            params: this._exportService.generateCSVString(action.statistics)
                        }
                    })
                )
            })
        )
    )
    exportStatistics1$ = createEffect(() =>
        this._actions$.pipe(
            ofType(exportStatistics1Action),
            switchMap((action) => {
                return of(
                    showDialogAction({
                        info: {
                            modalId: GLOBAL_MODAL_ID,
                            title: 'STATISTICS.EXPORT.TITLE',
                            okLabel: 'LABELS.OK',
                            cancelLabel: 'LABELS.CANCEL',
                            type: action.type,
                            params: this._exportService.generateCSVString(action.statistics)
                        }
                    })
                )
            })
        )
    )
    exportStatistics2$ = createEffect(() =>
        this._actions$.pipe(
            ofType(exportStatistics2Action),
            switchMap((action) => {
                return of(
                    showDialogAction({
                        info: {
                            modalId: GLOBAL_MODAL_ID,
                            title: 'STATISTICS.EXPORT.TITLE',
                            okLabel: 'LABELS.OK',
                            cancelLabel: 'LABELS.CANCEL',
                            type: action.type,
                            params: this._exportService.generateCSVString(action.statistics)
                        }
                    })
                )
            })
        )
    )
    exportStatistics3$ = createEffect(() =>
        this._actions$.pipe(
            ofType(exportStatistics3Action),
            switchMap((action) => {
                return of(
                    showDialogAction({
                        info: {
                            modalId: GLOBAL_MODAL_ID,
                            title: 'STATISTICS.EXPORT.TITLE',
                            okLabel: 'LABELS.OK',
                            cancelLabel: 'LABELS.CANCEL',
                            type: action.type,
                            params: this._exportService.generateCSVString(action.statistics)
                        }
                    })
                )
            })
        )
    )

    exportStatisticsConfirm$ = createEffect(() =>
        this._actions$.pipe(
            ofType(exportStatisticsConfirmAction),
            switchMap((action) => {
                this._exportService.generateAndDownloadCSV(action.csvContent, action.fileName);
                return of(
                    showInfoModalAction({
                        info: {
                            modalId: GLOBAL_MODAL_ID,
                            title: 'STATISTICS.EXPORT.SUCCESS_TITLE',
                            okLabel: 'LABELS.OK',
                            type: action.type
                        }
                    })
                )
            })
        )
    )

}
