import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, finalize, map, Observable, of, switchMap } from "rxjs";
import { GLOBAL_MODAL_ID, WARNING_ICON } from "../../constants";
import { StationsService } from "../../services/stations.service";
import { hideLoading } from "../loading/loading.actions";
import { hideModalAction, showDialogAction, showInfoModalAction } from "../modal/modal.actions";
import { createStationAction, createStationSuccessAction, deleteStationAction, deleteStationConfirmAction, editSelectStationAction, editStationAction, editStationSuccessAction, exportStationsAction, exportStationsConfirmAction, getStationsAction, getStationsSuccessAction } from "./stations.actions";
import { ExportsService } from "../../services/exports.service";

@Injectable({
  providedIn: 'root'
})
export class StationsEffects {
  private _actions$ = this.injector.get(Actions);
  private _store = this.injector.get(Store);
  private _stationsService = this.injector.get(StationsService);
  private _router = this.injector.get(Router);
  private _exportService = this.injector.get(ExportsService);

  constructor(
    private injector: Injector
  ) { }

  getStations$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getStationsAction),
      switchMap((action) => {
        return this._stationsService.getStations(action.queryFilters).pipe(
          finalize(() => {
            this._store.dispatch(hideLoading());
          }),
          map((response) => {
            return getStationsSuccessAction({
              stationsList: response.data
            })
          }),
          catchError((error) => {
            let info = {
              title: 'ERRORS.TITLES.GENERAL',
              message: '',
              icon: WARNING_ICON,
              okLabel: 'LABELS.OK',
            }
            return this.handleError(error, info);
          })
        )
      })
    )
  )

  createStationSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(createStationSuccessAction),
      switchMap(() => {
        this._router.navigate(['stations']);
        return of(
          hideModalAction()
        )
      })
    )
  );

  createStation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(createStationAction),
      switchMap((action) => {
        return this._stationsService.createStation(action.body).pipe(
          finalize(() => {
            this._store.dispatch(hideLoading())
          }),
          map((response) => {
            return showInfoModalAction({
              info: {
                modalId: GLOBAL_MODAL_ID,
                title: 'STATIONS.CREATE.SUCCESS_TITLE',
                okLabel: 'LABELS.OK',
                type: action.type
              }
            })
          }),
          catchError((error) => {
            let info = {
              title: 'ERRORS.TITLES.GENERAL',
              message: error.message,
              icon: WARNING_ICON,
              okLabel: 'LABELS.OK',
            }
            return this.handleError(error, info);
          })
        )
      })
    )
  );

  editStation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(editStationAction),
      switchMap((action) => {
        return this._stationsService.editStation(action.body, action.id).pipe(
          finalize(() => {
            this._store.dispatch(hideLoading())
          }),
          map((response) => {
            return showInfoModalAction({
              info: {
                modalId: GLOBAL_MODAL_ID,
                title: 'STATIONS.EDIT.SUCCESS_TITLE',
                okLabel: 'LABELS.OK',
                type: action.type
              }
            })
          }),
          catchError((error) => {
            let info = {
              title: 'ERRORS.TITLES.GENERAL',
              message: error.message,
              icon: WARNING_ICON,
              okLabel: 'LABELS.OK',
            }
            return this.handleError(error, info);
          })
        )
      })
    )
  );

  editStationSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(editStationSuccessAction),
      switchMap(() => {
        this._store.dispatch(editSelectStationAction({ station: null }));
        this._router.navigate(['stations']);
        return of(
          hideModalAction()
        )
      })
    )
  );

  deleteStation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(deleteStationAction),
      switchMap((action) => {
        let info = {
          modalId: GLOBAL_MODAL_ID,
          title: 'STATIONS.DELETE.TITLE',
          message: 'STATIONS.DELETE.MESSAGE',
          messageParams: `${action.station.stationId}`,
          okLabel: 'LABELS.OK',
          cancelLabel: 'LABELS.CANCEL',
          type: action.type,
          params: {
            id: action.station.id
          }
        };
        return of(
          showDialogAction({
            info: info
          })
        )
      })
    )
  );

  deleteStationConfirm$ = createEffect(() =>
    this._actions$.pipe(
      ofType(deleteStationConfirmAction),
      switchMap((action) => {
        return this._stationsService.deleteStation(action.stationId).pipe(
          finalize(() => {
            this._store.dispatch(hideLoading());
          }),
          map((response) => {
            this._stationsService.updateStations.next();
            return showInfoModalAction({
              info: {
                modalId: GLOBAL_MODAL_ID,
                title: 'STATIONS.DELETE.SUCCESS_TITLE',
                okLabel: 'LABELS.OK',
                type: action.type
              }
            })
          }),
          catchError((error) => {
            let info = {
              title: 'ERRORS.TITLES.GENERAL',
              message: error.message,
              icon: WARNING_ICON,
              okLabel: 'LABELS.OK',
            }
            return this.handleError(error, info);
          })
        )
      })
    )
  );

  exportStations$ = createEffect(() =>
    this._actions$.pipe(
      ofType(exportStationsAction),
      switchMap((action) => {
        return of(
          showDialogAction({
            info: {
              modalId: GLOBAL_MODAL_ID,
              title: 'STATIONS.EXPORT.TITLE',
              okLabel: 'LABELS.OK',
              cancelLabel: 'LABELS.CANCEL',
              type: action.type,
              params: this._exportService.generateCSVString(action.stations)
            }
          })
        )
      })
    )
  )

  exportStationsConfirm$ = createEffect(() =>
    this._actions$.pipe(
      ofType(exportStationsConfirmAction),
      switchMap((action) => {
        this._exportService.generateAndDownloadCSV(action.csvContent, action.fileName);
        return of(
          showInfoModalAction({
            info: {
              modalId: GLOBAL_MODAL_ID,
              title: 'STATIONS.EXPORT.SUCCESS_TITLE',
              okLabel: 'LABELS.OK',
            }
          })
        )
      })
    )
  )

  private handleError(response: HttpErrorResponse, info: any): Observable<any> {
    let modalInfo = {
      ...info,
      show: true,
      modalId: GLOBAL_MODAL_ID,
      message: response.error ? response.error.message : response.error
    };
    return of(
      this._store.dispatch(showInfoModalAction({ info: modalInfo }))
    );
  }

}
