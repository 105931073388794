import { createAction, props } from '@ngrx/store';
import { ACTION_CREATE_CLIENTS, ACTION_CREATE_CLIENTS_SUCCESS, ACTION_DELETE_CLIENTS, ACTION_DELETE_CLIENTS_CONFIRM, ACTION_DELETE_USER_SUCCESS, ACTION_EDIT_CLIENTS, ACTION_EDIT_USER_SUCCESS, ACTION_EXPORT_CLIENTS, ACTION_EXPORT_CLIENTS_SUCCESS, ACTION_EXPORT_CLIENTS_CONFIRM, ACTION_GET_CLIENTS, ACTION_GET_CLIENTS_SUCCESS, ACTION_EDIT_SELECT_CLIENT, ACTION_EDIT_CLIENTS_SUCCESS, } from '../../data-types/actions.enum';
import { IClients, IClientsListState } from './clients.state';

export const createClientsAction = createAction(
  ACTION_CREATE_CLIENTS,
  props<{ body: any }>()
)
export const createClientsSuccessAction = createAction(
  ACTION_CREATE_CLIENTS_SUCCESS
)
export const getClientsAction = createAction(
  ACTION_GET_CLIENTS,
  props<{ queryFilters: string }>()
)
export const getClientsSuccessAction = createAction(
  ACTION_GET_CLIENTS_SUCCESS,
  props<{ clientsList: IClientsListState }>()
)
export const deleteClientsAction = createAction(
  ACTION_DELETE_CLIENTS,
  props<{ clients: IClients }>()
)
export const deleteClientsConfirmAction = createAction(
  ACTION_DELETE_CLIENTS_CONFIRM,
  props<{ userId: number }>()
)
export const deleteClientsSuccessAction = createAction(
  ACTION_DELETE_USER_SUCCESS
)

export const editSelectClientAction = createAction(
  ACTION_EDIT_SELECT_CLIENT,
  props<{ client: IClients | null }>()
)
export const editClientsAction = createAction(
  ACTION_EDIT_CLIENTS,
  props<{ body: any, clientId: number }>()
)
export const editClientsSuccessAction = createAction(
  ACTION_EDIT_CLIENTS_SUCCESS
)

export const exportClientsAction = createAction(
  ACTION_EXPORT_CLIENTS,
  props<{ clients: IClients[] }>()
)
export const exportClientsConfirmAction = createAction(
  ACTION_EXPORT_CLIENTS_CONFIRM,
  props<{ csvContent: string, fileName: string }>()
)
export const exportClientsSuccessAction = createAction(
  ACTION_EXPORT_CLIENTS_SUCCESS,
)
