import { createReducer, on, Action } from '@ngrx/store';
import { hideModalAction, showDialogAction, showInfoModalAction } from './modal.actions';
import { IModalState, initialModalState } from './modal.state';


export const modalReducer = createReducer(
  initialModalState,
  on(showInfoModalAction, (state: IModalState, {info}) => {
    return ({
      ...state,
      show: true,
      modalId: info.modalId,
      title: info.title,
      message: info.message,
      messageParams: info.messageParams,
      icon: info.message,
      okLabel: info.okLabel,
      cancelLabel: info.cancelLabel,
      actionType: info.type,
      params: info.params,
      switchText: info.switchText
    })
  }),
  on(showDialogAction, (state: IModalState, {info}) => {
    return ({
      ...state,
      show: true,
      modalId: info.modalId,
      title: info.title,
      message: info.message,
      messageParams: info.messageParams,
      icon: info.message,
      okLabel: info.okLabel,
      cancelLabel: info.cancelLabel,
      actionType: info.type,
      params: info.params,
      switchText: info.switchText
    })
  }),
  on(hideModalAction, (state: IModalState) => {
    return ({
      ...initialModalState
    })
  })
)

export function modalReducers(state: IModalState = initialModalState, action: Action) {
  return modalReducer(state, action)
}
