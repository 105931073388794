import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EButtonStyles } from '../../data-types/button.enum';

@Component({
  selector: 'backoffice-button',
  templateUrl: './backoffice-button.component.html',
  styleUrls: ['./backoffice-button.component.scss']
})
export class BackofficeButtonComponent implements OnInit {

  @Input('id') id: string="";
  @Input("label") label: string ="";
  @Input("style") style: EButtonStyles = EButtonStyles.PRIMARY;
  @Input("disabled") disabled: boolean = false;
  @Output("onClick") onClick: any = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  public buttonIsClicked(event: Event){
    this.onClick.emit(event);
  }

}
