import { Injectable, Injector } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { map } from "rxjs";
import { ModalService } from "../../services/modal.service";
import { hideModalAction, hideModalSuccessAction, openModalAction, openModalSuccessAction } from "./modal.actions";

@Injectable()
export class ModalEffects {
  private _actions$ = this.injector.get(Actions);
  private _modalService = this.injector.get(ModalService);

  constructor(
    private injector: Injector
  ) { }

  openModal$ = createEffect(() =>
    this._actions$.pipe(
      ofType(openModalAction),
      map((action) => {
        this._modalService.open(action.modalId);
        return openModalSuccessAction();
      })
    )
  )

  hideModal$ = createEffect(() =>
    this._actions$.pipe(
      ofType(hideModalAction),
      map((action) => {
        this._modalService.closeAll();
        return hideModalSuccessAction();
      })
    )
  )
}
