import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, finalize, map, Observable, of, switchMap, tap } from "rxjs";
import { GLOBAL_MODAL_ID, WARNING_ICON } from "../../constants";
import { IncidencesService } from "../../services/incidences.service";
import { hideLoading } from "../loading/loading.actions";
import { showInfoModalAction } from "../modal/modal.actions";
import { getBookingIncidencesAction, getBookingIncidencesSuccessAction } from "./booking-incidences.actions";


@Injectable({
  providedIn: 'root'
})
export class BookingIncidencesEffects {
  private _actions = this.injector.get(Actions);
  private _store = this.injector.get(Store);
  private _incidencesService = this.injector.get(IncidencesService);

  constructor(
    private injector: Injector
  ) { }

  getBookingIncidences$ = createEffect(() =>
    this._actions.pipe(
      ofType(getBookingIncidencesAction),
      switchMap((action) => {
        return this._incidencesService.getBookingIncidences(action.queryFilter).pipe(

          finalize(() => {
            this._store.dispatch(hideLoading());
          }),
          map((response) => {
            return getBookingIncidencesSuccessAction({
              incidencesList: response.data
            })
          }),
          catchError((error) => {
            let info = {
              title: 'ERRORS.TITLES.GENERAL',
              message: error.message,
              icon: WARNING_ICON,
              okLabel: 'LABELS.OK',
            }
            return this.handleError(error, info);
          })
        )
      })
    )
  )

  private handleError(response: HttpErrorResponse, info: any): Observable<any> {
    let modalInfo = {
      ...info,
      show: true,
      modalId: GLOBAL_MODAL_ID,
      message: response.error ? response.error.message : response.error
    };
    return of(
      this._store.dispatch(showInfoModalAction({ info: modalInfo }))
    );
  }

}
