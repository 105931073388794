import { IClients } from "../clients/clients.state";

export interface IStationsListState {
  totalElements: number,
  totalPages: number,
  stations: IStation[],
  selectedStation: IStation | null
  isLoading: boolean
}

export interface IStation {
  forEach(arg0: (station: any) => void): unknown;
  map(arg0: (station: any) => void): unknown;
  clientId: number
  client: IClients;
  typeStation: any;
  stationType: any;
  active: boolean,
  alarmActivated: boolean,
  battery: boolean,
  city: string,
  id: number,
  latitude: number,
  longitude: number,
  mupiActivated: boolean,
  mupiConnected: boolean,
  numBattery: number,
  owner: string,
  postalCode: string,
  stationId: string,
  street: string,
  totalBoxes: number,
  boxes: any,
  createdDate: Date,
  communicationFailure: boolean,
  batteryChargeAutomaticPercentageControl: number,
  batteryChargeManualControl: boolean,
  batteryChargeAlarm: boolean,
  indoorLight: boolean,
  indoorLightAutomaticPercentageControl: number,
  indoorLightManualControl: boolean,
  indoorLightAlarm: boolean,
  outdoorLight: boolean,
  outdoorLightAutomaticPercentageControl: number,
  outdoorLightManualControl: boolean,
  outdoorLightAlarm: boolean,
  mupiAutomaticPercentageControl: number,
  mupiManualControl: boolean,
  mupiAlarm: boolean

}

export const initialStationsListState: IStationsListState = {
  totalElements: 0,
  totalPages: 1,
  stations: [],
  selectedStation: null,
  isLoading: true
}
