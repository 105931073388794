import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable, BehaviorSubject, of, throwError } from "rxjs";
import { catchError, filter, finalize, switchMap, take, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { EStorageType } from 'src/app/shared/data-types/storage.enum';
import { EStatusError } from "../data-types/status_errors.enum";
import { AuthService } from "../services/auth.service";
import { loginErrorAction } from "../store/auth/auth.actions";
import { hideLoading } from "../store/loading/loading.actions";
import { Store } from "@ngrx/store";

@Injectable()
export class Interceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private router: Router,
    private translate: TranslateService,
    private authService: AuthService,
    private store: Store
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(EStorageType.Token);
    const currentLang = this.translate.currentLang;

    request = request.clone({
      headers: request.headers.set('Content-type', 'application/json')
    });

    if (token) {
      request = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${token}`)
      });
    }

    if (currentLang) {
      request = request.clone({
        headers: request.headers.set('Accept-language', currentLang)
      });
    }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === EStatusError.UNAUTHORIZED) {
          return this.handle401Error(request, next);
        } else {
          return this.catchError(error);
        }
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = localStorage.getItem(EStorageType.RefreshToken);
      if (refreshToken) {
        return this.authService.refreshToken(refreshToken).pipe(
          switchMap((res: any) => {
            this.isRefreshing = false;
            localStorage.setItem(EStorageType.Token, res.data.accessToken);
            this.refreshTokenSubject.next(res.data.accessToken);
            return next.handle(this.addTokenHeader(request, res.data.accessToken));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            localStorage.clear();
            this.router.navigate(['/login']);
            return this.catchError(err);
          }),
          finalize(() => {
            this.store.dispatch(hideLoading());
          })
        );
      } else {
        this.isRefreshing = false;
        localStorage.clear();
        this.router.navigate(['/login']);
        return of();
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addTokenHeader(request, token));
        }),
        finalize(() => {
          this.store.dispatch(hideLoading());
        })
      );
    }
  }

  private addTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`)
    });
  }

  private catchError(response: HttpErrorResponse): Observable<any> {
    return of(
      loginErrorAction({
        error: {
          status: response.error.status,
          message: response.error.message || response.statusText
        }
      })
    );
  }
}
