import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, every } from 'rxjs';
import { EButtonStyles } from '../../data-types/button.enum';
import { ModalService } from '../../services/modal.service';
import { Store } from '@ngrx/store';
import { hideModalAction } from '../../store/modal/modal.actions';

@Component({
  selector: 'backoffice-modal',
  templateUrl: './backoffice-modal.component.html',
  styleUrls: ['./backoffice-modal.component.scss']
})
export class BackofficeModalComponent implements OnInit {

  display$: Observable<'open' | 'close'> | undefined;
  toggle: boolean = false
  @Input() modalId: string = ""
  @Input('title') title: string =""
  @Input('message') message: string =""
  @Input('okLabel') okLabel: string =""
  @Input('noLabel') noLabel: string =""
  @Input('icon') icon: string = ""
  @Input('switchText') switchText: string = ""
  @Output('onOkButton') onOkButton: any = new EventEmitter()
  @Output('onNoButton') onNoButton: any = new EventEmitter()
  @Output('onCloseModal') onCloseModal: any = new EventEmitter()

  private element: any
  buttonStyles = EButtonStyles

  constructor(
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    this.display$ = this.modalService.watch(this);
  }

  close() {
    this.onCloseModal.emit();
    this.modalService.closeAll()
  }

  onOkClick(event: MouseEvent) {
    this.onOkButton.emit({event:event, toggle: this.toggle})
  }

  onNoClick(event: MouseEvent) {
    this.onNoButton.emit({event:event, toggle: this.toggle})
  }

  onActiveToggle(event) {
    this.toggle = event
  }

}
