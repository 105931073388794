import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { PAGE_SIZE_TABLES } from "../constants";

@Injectable({
  providedIn: 'root'
})
export class StationsService {
  private API_URL = environment.API_URL;

  updateStations: Subject<void> = new Subject();

  constructor(
    private http: HttpClient
  ) { }

  getStationById(id: string): Observable<any> {
    return this.http.get(`${this.API_URL}/cms/stations/${id}`);
  }

  getStations(queryFilter: string): Observable<any> {
    return this.http.get(`${this.API_URL}/cms/stations${queryFilter}`);
  }

  getBookings(id: number, page: number): Observable<any> {
    return this.http.get(`${this.API_URL}/cms/stations/${id}/bookings?page=${page}&pageSize=${PAGE_SIZE_TABLES}`);
  }

  createStation(body: any): Observable<any> {
    return this.http.post(`${this.API_URL}/cms/stations`, body);
  }

  editStation(body: any, id: number): Observable<any> {
    return this.http.patch(`${this.API_URL}/cms/stations/${id}`, body);
  }

  getBoxes(stationId: number): Observable<any> {
    return this.http.get(`${this.API_URL}/cms/stations/${stationId}/boxes`);
  }

  syncBoxes(stationId: string): Observable<any> {
    return this.http.patch(`${this.API_URL}/cms/stations/${stationId}/sync`, {});
  }

  deleteStation(stationId: number): Observable<any> {
    return this.http.delete(`${this.API_URL}/cms/stations/${stationId}`);
  }
  openBox(stationId: number, boxId: number) {
    return this.http.patch(`${this.API_URL}/cms/stations/${stationId}/boxes/${boxId}/open`, {});
  }
  finishBooking(stationId: number, boxId: number, body: any) {
    return this.http.patch(`${this.API_URL}/cms/stations/${stationId}/boxes/${boxId}/finish-booking`, body);
  }
  resetStation(stationId: string, body: any) {
    return this.http.post(`${this.API_URL}/cms/stations/${stationId}/reset`, body);
  }
  getTypeOfStations() {
    return this.http.get(`${this.API_URL}/cms/stations/types`);
  }

  assingClientsToStation(stationId, body: any) {
    return this.http.patch(`${this.API_URL}/cms/stations/${stationId}/assignations`, body);
  }
}
