import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EButtonStyles } from 'src/app/shared/data-types/button.enum';
import { EStatusError } from 'src/app/shared/data-types/status_errors.enum';
import { EStorageType } from 'src/app/shared/data-types/storage.enum';
import { Store, select } from '@ngrx/store';
import { showLoading } from 'src/app/shared/store/loading/loading.actions';
import { loginAction } from 'src/app/shared/store/auth/auth.actions';
import { selectAuthStateError } from 'src/app/shared/store/auth/auth.selector';
import { Observable, Subject, takeUntil } from 'rxjs';
import { IAppState } from 'src/app/shared/store/app.state';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public EStatusError = EStatusError
  loginForm: FormGroup;
  statusError = null;
  public errorMessage = "";

  EButtonStyles = EButtonStyles;

  authErrorSelector$: Observable<any> = this.store.pipe(select(selectAuthStateError));

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private store: Store<IAppState>,
    private translateService: TranslateService
  ) {
    this.loginForm = this.formBuilder.group({
      email: new FormControl("", [
        Validators.required,
        Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
      ]),
      password: new FormControl("", [
        Validators.required
      ])
    })
  }

  ngOnInit() {
    let userLogged = this.getLocalStorageUser();
    let token = this.getLocalStorageToken();
    if (userLogged && token) {
      this.router.navigate(['/stations'])
    }
    this.authErrorSelector$.pipe(takeUntil(this.destroy$)).subscribe((error) => {
      if (error) {
        this.statusError = error.status;
        if (this.statusError == EStatusError.UNAUTHORIZED) {
          this.errorMessage = this.translateService.instant("LOGIN.FAIL");
        } else {
          this.errorMessage = error.message;
        }
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  onLogin() {
    this.statusError = null;
    this.errorMessage = "";
    this.store.dispatch(showLoading());
    if (this.loginForm.valid) {
      this.store.dispatch(loginAction(this.loginForm.value))
    }
  }

  getControl(key: string) {
    return this.loginForm.get(key) as FormControl
  }

  getLocalStorageToken() {
    return localStorage.getItem(EStorageType.Token)
  }

  getLocalStorageUser() {
    return localStorage.getItem(EStorageType.User)
  }

}
