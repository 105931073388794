<div>
  <div class="incidences-title">
    <h1>{{'MENU.INCIDENCES' | translate}}</h1>
  </div>
  <div class="incidences-tabs">
    <div class="incidences-tabs-item" *ngFor="let tab of tabs; let i = index" [class.activeTab]="activeTab===i"
      (click)="activeTab=i">
      {{tab.label}}
    </div>
  </div>
  <div class="incidences-wrapper">
    <div class="incidences-date-wrapper">
      <backoffice-date-picker [initDate]="tabs[activeTab].fromDate" [endDate]="tabs[activeTab].untilDate"
        (onChangeDate)="onDateChange($event)"></backoffice-date-picker>
    </div>
    <backoffice-table typeCompoent="incidences" [activeTab]="activeTab" [alarmIncidencesError]="alarmIncidencesError"
      [doorIncidencesError]="doorIncidencesError" [chargeIncidencesError]="chargeIncidencesError"
      [bookingIncidencesloading]="bookingIncidencesloading" [getStationsActionloading]="getStationsActionloading"
      [chargeIncidencesloading]="chargeIncidencesloading" [doorIncidencesloading]="doorIncidencesloading"
      [alarmIncidencesloading]="alarmIncidencesloading" [table]="tabs[activeTab].tableData"
      [currentPage]="tabs[activeTab].currentPage" [totalPages]="tabs[activeTab].totalPages"
      (onNextPageEmit)="onNextPage()" (onPreviousPageEmit)="onPreviousPage()" [hasActions]="false"
      [showTopBar]="false"></backoffice-table>
  </div>
</div>