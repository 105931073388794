<div class="table-footer">
  <div class="table-footer-pagination">
    <div *ngIf="currentPage > 1" class="arrow" (click)="onPreviousPage()">
      <img src="assets/img/icons/arrow-left.svg">
    </div>
    <div class="counter">
      {{ totalPages == 0 ? '0' : currentPage }}  {{ "LABELS.PAGINATION_OF" | translate }} {{ totalPages }}
    </div>
    <div *ngIf="currentPage < totalPages" class="arrow" (click)="onNextPage()">
      <img src="assets/img/icons/arrow-right.svg">
    </div>
  </div>
</div>
