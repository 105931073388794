import { createSelector } from "@ngrx/store";
import { IAppState } from "../app.state";
import { IStationsListState } from "./stations.state";

const selectStations = (state: IAppState) => state.stations;

export const selectStationsListStateTotalPages = createSelector(
  selectStations,
  (state: IStationsListState) => state.totalPages
)

export const selectStationsListStateStations = createSelector(
  selectStations,
  (state: IStationsListState) => state.stations
)

export const selectSelectedStationState = createSelector(
  selectStations,
  (state: IStationsListState) => state.selectedStation
)
