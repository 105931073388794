import { Injectable, Injector } from "@angular/core";
import { Store } from '@ngrx/store';
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { createUserAction, createUserSuccessAction, deleteUserAction, deleteUserConfirmAction, editSelectUserAction, editUserAction, editUserSuccessAction, exportUsersAction, exportUsersConfirmAction, getUsersAction, getUsersSuccessAction } from "./users.actions";
import { finalize, map, Observable, switchMap, catchError, of } from "rxjs";
import { UsersService } from "../../services/users.service";
import { hideLoading } from "../loading/loading.actions";
import { HttpErrorResponse } from "@angular/common/http";
import { hideModalAction, showDialogAction, showInfoModalAction } from "../modal/modal.actions";
import { GLOBAL_MODAL_ID, WARNING_ICON } from "../../constants";
import { IUser } from "./users.state";
import { Router } from "@angular/router";
import { ExportsService } from "../../services/exports.service";

@Injectable()
export class UsersEffects {
  private _actions$ = this.injector.get(Actions);
  private _store = this.injector.get(Store);
  private _usersService = this.injector.get(UsersService);
  private _router = this.injector.get(Router);
  private _exportService = this.injector.get(ExportsService);

  constructor(
    private injector: Injector,
  ) { }

  getUsers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getUsersAction),
      switchMap((action) => {
        return this._usersService.getUsers(action.queryFilters).pipe(
          finalize(() => {
            this._store.dispatch(hideLoading());
          }),
          map((response) => {
            let updatedUsersList: IUser[] = [];
            response.data.users.map((u: any) => {
              updatedUsersList.push({
                ...u,
                subscription: u.subscription ? u.subscription.toUpperCase() : '-',
                currentPeriodStart: u.current_period_start ? new Date(u.current_period_start) : '-',
                currentPeriodEnd: u.current_period_end ? new Date(u.current_period_end) : '-'
              })
            })
            return getUsersSuccessAction({
              usersList: {
                ...response.data,
                users: updatedUsersList
              }
            });
          }),
          catchError((error) => {
            let info = {
              title: 'ERRORS.TITLES.GENERAL',
              message: '',
              icon: WARNING_ICON,
              okLabel: 'LABELS.OK',
            }
            return this.handleError(error, info);
          })
        );
      })
    )
  );

  createUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(createUserAction),
      switchMap((action) => {
        return this._usersService.createUser(action.body).pipe(
          finalize(() => {
            this._store.dispatch(hideLoading())
          }),
          map((response) => {
            return showInfoModalAction({
              info: {
                modalId: GLOBAL_MODAL_ID,
                title: 'USERS.CREATE.SUCCESS_TITLE',
                okLabel: 'LABELS.OK',
                type: action.type
              }
            })
          }),
          catchError((error) => {
            let info = {
              title: 'ERRORS.TITLES.GENERAL',
              message: error.message,
              icon: WARNING_ICON,
              okLabel: 'LABELS.OK',
            }
            return this.handleError(error, info);
          })
        )
      })
    )
  );

  createUserSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(createUserSuccessAction),
      switchMap(() => {
        this._router.navigate(['users']);
        return of(
          hideModalAction()
        )
      })
    )
  );

  editUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(editUserAction),
      switchMap((action) => {
        return this._usersService.editUser(action.body, action.userId).pipe(
          finalize(() => {
            this._store.dispatch(hideLoading())
          }),
          map((response) => {
            return showInfoModalAction({
              info: {
                modalId: GLOBAL_MODAL_ID,
                title: 'USERS.EDIT.SUCCESS_TITLE',
                okLabel: 'LABELS.OK',
                type: action.type
              }
            })
          }),
          catchError((error) => {
            let info = {
              title: 'ERRORS.TITLES.GENERAL',
              message: error.message,
              icon: WARNING_ICON,
              okLabel: 'LABELS.OK',
            }
            return this.handleError(error, info);
          })
        )
      })
    )
  );

  editUserSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(editUserSuccessAction),
      switchMap(() => {
        this._store.dispatch(editSelectUserAction({ user: null }));
        this._router.navigate(['users']);
        return of(
          hideModalAction()
        )
      })
    )
  );

  deleteUser$ = createEffect(() =>
    this._actions$.pipe(
      ofType(deleteUserAction),
      switchMap((action) => {
        let info = {
          modalId: GLOBAL_MODAL_ID,
          title: 'USERS.DELETE.TITLE',
          message: 'USERS.DELETE.MESSAGE',
          messageParams: `${action.user.name} ${action.user.surname}`,
          okLabel: 'LABELS.OK',
          cancelLabel: 'LABELS.CANCEL',
          type: action.type,
          params: {
            id: action.user.id
          }
        };
        return of(
          showDialogAction({
            info: info
          })
        )
      })
    )
  );

  deleteUserConfirm$ = createEffect(() =>
    this._actions$.pipe(
      ofType(deleteUserConfirmAction),
      switchMap((action) => {
        return this._usersService.deleteUser(action.userId).pipe(
          finalize(() => {
            this._store.dispatch(hideLoading());
          }),
          map((response) => {
            this._usersService.updateUsers.next();
            return showInfoModalAction({
              info: {
                modalId: GLOBAL_MODAL_ID,
                title: 'USERS.DELETE.SUCCESS_TITLE',
                okLabel: 'LABELS.OK',
                type: action.type
              }
            })
          }),
          catchError((error) => {
            let info = {
              title: 'ERRORS.TITLES.GENERAL',
              message: error.message,
              icon: WARNING_ICON,
              okLabel: 'LABELS.OK',
            }
            return this.handleError(error, info);
          })
        )
      })
    )
  );

  exportUsers$ = createEffect(() =>
    this._actions$.pipe(
      ofType(exportUsersAction),
      switchMap((action) => {
        return of(
          showDialogAction({
            info: {
              modalId: GLOBAL_MODAL_ID,
              title: 'USERS.EXPORT.TITLE',
              okLabel: 'LABELS.OK',
              cancelLabel: 'LABELS.CANCEL',
              type: action.type,
              params: this._exportService.generateCSVString(action.users)
            }
          })
        )
      })
    )
  )

  exportUsersConfirm$ = createEffect(() =>
    this._actions$.pipe(
      ofType(exportUsersConfirmAction),
      switchMap((action) => {
        this._exportService.generateAndDownloadCSV(action.csvContent, action.fileName);
        return of(
          showInfoModalAction({
            info: {
              modalId: GLOBAL_MODAL_ID,
              title: 'USERS.EXPORT.SUCCESS_TITLE',
              okLabel: 'LABELS.OK',
              type: action.type
            }
          })
        )
      })
    )
  )

  private handleError(response: HttpErrorResponse, info: any): Observable<any> {
    let modalInfo = {
      ...info,
      show: true,
      modalId: GLOBAL_MODAL_ID,
      message: response.error ? response.error.message : response.error
    };
    return of(
      this._store.dispatch(showInfoModalAction({ info: modalInfo }))
    );
  }

}
