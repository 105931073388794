import { createReducer, on, Action } from '@ngrx/store';
import { loginAction, loginErrorAction, loginSuccessAction, logoutAction } from './auth.actions';
import { IAuthState, initialAuthState } from './auth.state';

export const authReducer = createReducer(
  initialAuthState,
  on(loginAction, (state: IAuthState) => {
    return ({
      ...state
    })
  }),
  on(loginSuccessAction, (state: IAuthState, {user}) => {
    return ({
      ...state,
      error: null
    })
  }),
  on(loginErrorAction, (state: IAuthState, {error}) => {
    return ({
      ...state,
      error: error
    })
  }),
  on(logoutAction, (state: IAuthState) => {
    return ({
      ...initialAuthState
    })
  })
)

export function authReducers(state: IAuthState = initialAuthState, action: Action) {
  return authReducer(state, action)
}
