import { createAction, props } from "@ngrx/store";
import { 
  ACTION_GET_BOOKING_INCIDENCES,
  ACTION_GET_BOOKING_INCIDENCES_SUCCESS, 
} from "../../data-types/actions.enum";
import { IBookingIncidencesListState } from "./booking-incidences.state";

export const getBookingIncidencesAction = createAction(
  ACTION_GET_BOOKING_INCIDENCES,
  props<{queryFilter: string}>()
)

export const getBookingIncidencesSuccessAction = createAction(
  ACTION_GET_BOOKING_INCIDENCES_SUCCESS,
  props<{incidencesList: IBookingIncidencesListState}>()
)
