import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  private API_URL = environment.API_URL;

  updateClients: Subject<void> = new Subject();

  constructor(
    private http: HttpClient
  ) { }


  createClients(body: any): Observable<any> {
    return this.http.post(`${this.API_URL}/cms/clients`, body);
  }
  getClients(queryFilter: string): Observable<any> {
    return this.http.get(`${this.API_URL}/cms/clients/list` + queryFilter);
  }

  getClientsDetails(clientId: string): Observable<any> {
    return this.http.get(`${this.API_URL}/cms/clients/${clientId}`);
  }

  editClients(body: any, clientId: number): Observable<any> {
    return this.http.patch(`${this.API_URL}/cms/clients/${clientId}`, body)
  }

  deleteClients(clientId: number): Observable<any> {
    return this.http.delete(`${this.API_URL}/cms/clients/${clientId}`);
  }
}
