import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Chart, ChartOptions } from 'chart.js/auto';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'backoffice-statics-linear',
  templateUrl: './backoffice-statistics-linear.html',
  styleUrls: ['./backoffice-statistics-linear.scss']
})
export class BackofficeStaticsLinearComponent implements AfterViewInit, OnChanges {

  public chart: Chart;
  @Input() id: string
  @Input() dataset0: any
  @Input() dataset1: any
  constructor(
    private translate: TranslateService

  ) { }
  ngAfterViewInit(): void {
    this.createChart();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataset0 && !changes.dataset0.firstChange) {
      if (changes.dataset0.currentValue.length > 0) {
        this.updateChart(this.chart, changes.dataset0.currentValue.map(item => item.key), changes.dataset0.currentValue.map(item => item.value));
      } else {
        this.updateChart(this.chart, [], []);
      }
    }

    if (changes.dataset1 && !changes.dataset1.firstChange) {
      if (changes.dataset1.currentValue.length > 0) {
        this.updateChart(this.chart, changes.dataset1.currentValue.map(item => item.key), changes.dataset1.currentValue.map(item => item.value));
      } else {
        this.updateChart(this.chart, [], []);
      }
    }
  }


  createChart() {
    const canvas = document.getElementById(this.id) as HTMLCanvasElement;


    const data = {
      labels: [],
      datasets: [{
        data: [],
        fill: false,
        borderColor: '#4FCA42',
        tension: 0.1
      }]
    };

    const options: ChartOptions<'line'> = {
      maintainAspectRatio: false,
      aspectRatio: 1.7,
      scales: {
        y: {
          title: this.id == 'linearChart1' && {
            display: true,
            text: this.translate.instant('STATISTICS.UNITS.MINUTES')
          },
          stacked: true,
          grid: {
            display: true,
          },

        },
        x: {
          grid: {
            display: false
          }
        }
      },
      responsive: true,
      plugins: {
        legend: {
          display: false
        }
      }
    };



    this.chart = new Chart(canvas, {
      type: 'line',
      data: data,
      options: options
    });



  }

  updateChart(chart: Chart, labels: string[], newData: number[]): void {
    chart.data.labels = [];
    chart.data.datasets.forEach((dataset) => {
      dataset.data = [];
    });


    chart.data.labels.push(...labels);
    chart.data.datasets.forEach((dataset) => {
      dataset.data.push(...newData);
    });

    chart.update();
  }

}
