import { Action, createReducer, on } from "@ngrx/store";
import { getBoxesSuccessAction, getStationType, getStationTypeSuccess } from "./boxes.actions";
import { IBoxesListState, initialBoxListState } from "./boxes.state";

export const boxReducer = createReducer(
  initialBoxListState,
  on(getBoxesSuccessAction, (state: IBoxesListState, { boxesList }) => {
    return ({
      ...state,
      boxesList: boxesList
    })
  }),
  on(getStationTypeSuccess, (state, { stationTypes }) => ({
    ...state,
    stationTypes: stationTypes
  }))
)

export function boxesReducers(state: IBoxesListState = initialBoxListState, action: Action) {
  return boxReducer(state, action)
}
