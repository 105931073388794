export interface IClientsListState {
  totalElements: number,
  totalPages: number,
  clients: IClients[],
  selectedClient: IClients | null
}
export interface IContacts {
  comments: string,
  createdDate: Date,
  email: string,
  id: number,
  lastModifiedDate: Date,
  name: string,
  phoneNumber: string
  position: string
}
export interface IClients {
  id: number,
  address: string,
  companyName: string,
  createdDate: Date,
  email: string,
  lastModifiedDate: Date,
  nif: string,
  postalCode: number,
  stationsNumber: number
  contacts: IContacts[]
}

export const initialClientsListState: IClientsListState = {
  totalElements: 0,
  totalPages: 1,
  clients: [],
  selectedClient: null
}
