import { Injectable, Injector } from "@angular/core";
import { Store } from '@ngrx/store';
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { createClientsAction, createClientsSuccessAction, deleteClientsAction, deleteClientsConfirmAction, editSelectClientAction, editClientsAction, editClientsSuccessAction, exportClientsAction, exportClientsConfirmAction, getClientsAction, getClientsSuccessAction } from "./clients.actions";
import { finalize, map, Observable, switchMap, catchError, of } from "rxjs";
import { ClientsService } from "../../services/clients.service";
import { hideLoading } from "../loading/loading.actions";
import { HttpErrorResponse } from "@angular/common/http";
import { hideModalAction, showDialogAction, showInfoModalAction } from "../modal/modal.actions";
import { GLOBAL_MODAL_ID, WARNING_ICON } from "../../constants";
import { IClients } from "./clients.state";
import { Router } from "@angular/router";
import { ExportsService } from "../../services/exports.service";

@Injectable()
export class ClientsEffects {
  private _actions$ = this.injector.get(Actions);
  private _store = this.injector.get(Store);
  private _clientsService = this.injector.get(ClientsService);
  private _router = this.injector.get(Router);
  private _exportService = this.injector.get(ExportsService);

  constructor(
    private injector: Injector,
  ) { }

  getClients$ = createEffect(() =>
    this._actions$.pipe(
      ofType(getClientsAction),
      switchMap((action) => {
        return this._clientsService.getClients(action.queryFilters).pipe(
          finalize(() => {
            this._store.dispatch(hideLoading());
          }),
          map((response) => {
            let updatedClientsList: IClients[] = [];
            response.data.users.map((c: any) => {
              updatedClientsList.push({
                ...c,
              })
            })
            return getClientsSuccessAction({
              clientsList: {
                ...response.data,
                clients: updatedClientsList
              }
            });
          }),
          catchError((error) => {
            let info = {
              title: 'ERRORS.TITLES.GENERAL',
              message: '',
              icon: WARNING_ICON,
              okLabel: 'LABELS.OK',
            }
            return this.handleError(error, info);
          })
        );
      })
    )
  );

  createClients$ = createEffect(() =>
    this._actions$.pipe(
      ofType(createClientsAction),
      switchMap((action) => {
        return this._clientsService.createClients(action.body).pipe(
          finalize(() => {
            this._store.dispatch(hideLoading())
          }),
          map((response) => {
            return showInfoModalAction({
              info: {
                modalId: GLOBAL_MODAL_ID,
                title: 'CLIENTS.CREATE.SUCCESS_TITLE',
                okLabel: 'LABELS.OK',
                type: action.type
              }
            })
          }),
          catchError((error) => {
            let info = {
              title: 'ERRORS.TITLES.GENERAL',
              message: error.message,
              icon: WARNING_ICON,
              okLabel: 'LABELS.OK',
            }
            return this.handleError(error, info);
          })
        )
      })
    )
  );

  createClientsSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(createClientsSuccessAction),
      switchMap(() => {
        this._router.navigate(['clients']);
        return of(
          hideModalAction()
        )
      })
    )
  );

  editClients$ = createEffect(() =>
    this._actions$.pipe(
      ofType(editClientsAction),
      switchMap((action) => {
        return this._clientsService.editClients(action.body, action.clientId).pipe(
          finalize(() => {
            this._store.dispatch(hideLoading())
          }),
          map((response) => {
            return showInfoModalAction({
              info: {
                modalId: GLOBAL_MODAL_ID,
                title: 'CLIENTS.EDIT.SUCCESS_TITLE',
                okLabel: 'LABELS.OK',
                type: action.type
              }
            })
          }),
          catchError((error) => {
            let info = {
              title: 'ERRORS.TITLES.GENERAL',
              message: error.message,
              icon: WARNING_ICON,
              okLabel: 'LABELS.OK',
            }
            return this.handleError(error, info);
          })
        )
      })
    )
  );

  editClientsSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(editClientsSuccessAction),
      switchMap(() => {
        this._store.dispatch(editSelectClientAction({ client: null }));
        this._router.navigate(['clients']);
        return of(
          hideModalAction()
        )
      })
    )
  );

  deleteClients$ = createEffect(() =>
    this._actions$.pipe(
      ofType(deleteClientsAction),
      switchMap((action) => {
        let info = {
          modalId: GLOBAL_MODAL_ID,
          title: 'CLIENTS.DELETE.TITLE',
          message: 'CLIENTS.DELETE.MESSAGE',
          messageParams: `${action.clients.companyName}`,
          okLabel: 'LABELS.OK',
          cancelLabel: 'LABELS.CANCEL',
          type: action.type,
          params: {
            id: action.clients.id
          }
        };
        return of(
          showDialogAction({
            info: info
          })
        )
      })
    )
  );

  deleteClientsConfirm$ = createEffect(() =>
    this._actions$.pipe(
      ofType(deleteClientsConfirmAction),
      switchMap((action) => {
        return this._clientsService.deleteClients(action.userId).pipe(
          finalize(() => {
            this._store.dispatch(hideLoading());
          }),
          map((response) => {
            this._clientsService.updateClients.next();
            return showInfoModalAction({
              info: {
                modalId: GLOBAL_MODAL_ID,
                title: 'CLIENTS.DELETE.SUCCESS_TITLE',
                okLabel: 'LABELS.OK',
                type: action.type
              }
            })
          }),
          catchError((error) => {
            let info = {
              title: 'ERRORS.TITLES.GENERAL',
              message: error.message,
              icon: WARNING_ICON,
              okLabel: 'LABELS.OK',
            }
            return this.handleError(error, info);
          })
        )
      })
    )
  );

  exportClients$ = createEffect(() =>
    this._actions$.pipe(
      ofType(exportClientsAction),
      switchMap((action) => {
        return of(
          showDialogAction({
            info: {
              modalId: GLOBAL_MODAL_ID,
              title: 'CLIENTS.EXPORT.TITLE',
              okLabel: 'LABELS.OK',
              cancelLabel: 'LABELS.CANCEL',
              type: action.type,
              params: this._exportService.generateCSVString(action.clients)
            }
          })
        )
      })
    )
  )

  exportClientsConfirm$ = createEffect(() =>
    this._actions$.pipe(
      ofType(exportClientsConfirmAction),
      switchMap((action) => {
        this._exportService.generateAndDownloadCSV(action.csvContent, action.fileName);
        return of(
          showInfoModalAction({
            info: {
              modalId: GLOBAL_MODAL_ID,
              title: 'CLIENTS.EXPORT.SUCCESS_TITLE',
              okLabel: 'LABELS.OK',
            }
          })
        )
      })
    )
  )

  private handleError(response: HttpErrorResponse, info: any): Observable<any> {
    let modalInfo = {
      ...info,
      show: true,
      modalId: GLOBAL_MODAL_ID,
      message: response.error ? response.error.message : response.error
    };
    return of(
      this._store.dispatch(showInfoModalAction({ info: modalInfo }))
    );
  }

}
