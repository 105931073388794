import { NgModule } from "@angular/core";
import { SharedModule } from "../../shared.module";
import { BackofficeLoadingComponent } from "./backoffice-loading.component";

@NgModule({
  declarations: [
    BackofficeLoadingComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    BackofficeLoadingComponent
  ]
})
export class BackofficeLoadingModule {}
