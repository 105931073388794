export interface IBoxState {
  id: string,
  alarm: boolean,
  activated: boolean,
  occupied: boolean,
  battery: boolean,
  lightState: number,
  lockState: number,
  stationTypes: string[],

}

export interface IBoxesListState {
  stationTypes: any
  boxes: IBoxState[]
}

export const initialBoxListState: IBoxesListState = {
  boxes: [],
  stationTypes: []
}
