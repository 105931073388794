import { createAction, props } from "@ngrx/store";
import { ACTION_FINISH_BOOKING, ACTION_FINISH_BOOKING_CONFIRM, ACTION_GET_BOXES, ACTION_GET_BOXES_SUCCESS, ACTION_GET_STATIONS_TYPE, ACTION_GET_STATIONS_TYPE_SUCCESS, ACTION_OPEN_BOX, ACTION_OPEN_BOX_CONFIRM, ACTION_RESET_STATION, ACTION_RESET_STATION_CONFIRM } from "../../data-types/actions.enum";
import { IBoxesListState } from "./boxes.state";

export const getBoxesAction = createAction(
  ACTION_GET_BOXES,
  props<{ stationId: number }>()
)
export const getBoxesSuccessAction = createAction(
  ACTION_GET_BOXES_SUCCESS,
  props<{ boxesList: IBoxesListState }>()
)

export const openBoxAction = createAction(
  ACTION_OPEN_BOX,
  props<{ stationId: number, boxId: number }>()
)

export const openBoxConfirmAction = createAction(
  ACTION_OPEN_BOX_CONFIRM,
  props<{ stationId: number, boxId: number }>()
)

export const finishBookingAction = createAction(
  ACTION_FINISH_BOOKING,
  props<{ stationId: number, boxId: number }>()
)

export const finishBookingConfirmAction = createAction(
  ACTION_FINISH_BOOKING_CONFIRM,
  props<{ stationId: number, boxId: number, applyPenalty: boolean }>()
)


export const resetStationAction = createAction(
  ACTION_RESET_STATION,
  props<{ stationId: string, body: any }>()
);

export const resetStationActionConfim = createAction(
  ACTION_RESET_STATION_CONFIRM,
  props<{ stationId: string, body: any }>()
)

export const getStationType = createAction(
  ACTION_GET_STATIONS_TYPE
);

export const getStationTypeSuccess = createAction(
  ACTION_GET_STATIONS_TYPE_SUCCESS,
  props<{ stationTypes: {} }>()
);