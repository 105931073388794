import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, finalize, map, Observable, of, switchMap } from "rxjs";
import { GLOBAL_MODAL_ID, WARNING_ICON } from "../../constants";
import { IncidencesService } from "../../services/incidences.service";
import { hideLoading } from "../loading/loading.actions";
import { showInfoModalAction } from "../modal/modal.actions";
import {
  getAlarmIncidencesAction,
  getAlarmIncidencesSuccessAction,
  getDoorIncidencesAction,
  getDoorIncidencesSuccessAction,
  getChargeIncidencesAction,
  getChargeIncidencesSuccessAction,
  getIncidencesAlarmFailureAction,
  getIncidenceDoorsFailureAction,
  getIncidencesChargeFailureAction
} from "./box-incidences.actions";

@Injectable({
  providedIn: 'root'
})
export class IncidencesEffects {
  private _actions = this.injector.get(Actions);
  private _store = this.injector.get(Store);
  private _incidencesService = this.injector.get(IncidencesService);

  constructor(
    private injector: Injector
  ) { }

  getAlarmBoxIncidences$ = createEffect(() =>
    this._actions.pipe(
      ofType(getAlarmIncidencesAction),
      switchMap((action) => {
        return this._incidencesService.getIncidences(2, action.queryFilter).pipe(
          finalize(() => {
            this._store.dispatch(hideLoading());
          }),
          map((response) => {
            return getAlarmIncidencesSuccessAction({
              incidencesList: response.data
            })
          }),
          catchError((error) => {
            return of(getIncidencesAlarmFailureAction({ error: true }));
          })

          // catchError((error) => {
          //   let info = {
          //     title: 'ERRORS.TITLES.GENERAL',
          //     message: error.message,
          //     icon: WARNING_ICON,
          //     okLabel: 'LABELS.OK',
          //   }
          //   return this.handleError(error, info);
          // })
        )
      })
    )
  )

  getDoorBoxIncidences$ = createEffect(() =>
    this._actions.pipe(
      ofType(getDoorIncidencesAction),
      switchMap((action) => {
        return this._incidencesService.getIncidences(3, action.queryFilter).pipe(
          finalize(() => {
            this._store.dispatch(hideLoading());
          }),
          map((response) => {
            return getDoorIncidencesSuccessAction({
              incidencesList: response.data
            })
          }),
          catchError((error) => {
            return of(getIncidenceDoorsFailureAction({ error: true }));
          })
          // catchError((error) => {
          //   let info = {
          //     title: 'ERRORS.TITLES.GENERAL',
          //     message: error.message,
          //     icon: WARNING_ICON,
          //     okLabel: 'LABELS.OK',
          //   }
          //   return this.handleError(error, info);
          // })
        )
      })
    )
  )

  getChargeBoxIncidences$ = createEffect(() =>
    this._actions.pipe(
      ofType(getChargeIncidencesAction),
      switchMap((action) => {
        return this._incidencesService.getIncidences(4, action.queryFilter).pipe(

          finalize(() => {
            this._store.dispatch(hideLoading());
          }),
          map((response) => {

            return getChargeIncidencesSuccessAction({
              incidencesList: response.data
            })
          }),

          catchError((error) => {
            return of(getIncidencesChargeFailureAction({ error: true }));
          })
          //catchError((error) => {


          //   let info = {
          //     title: 'ERRORS.TITLES.GENERAL',
          //     message: error.message,
          //     icon: WARNING_ICON,
          //     okLabel: 'LABELS.OK',
          //   }
          //   return this.handleError(error, info);

          //})
        )
      })
    )
  )

  private handleError(response: HttpErrorResponse, info: any): Observable<any> {
    let modalInfo = {
      ...info,
      show: true,
      modalId: GLOBAL_MODAL_ID,
      message: response.error ? response.error.message : response.error
    };
    return of(
      this._store.dispatch(showInfoModalAction({ info: modalInfo }))
    );
  }

}
