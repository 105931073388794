import { Component, OnInit } from '@angular/core';
import { EButtonStyles } from '../../data-types/button.enum';
import { Store } from '@ngrx/store';
import { logoutAction } from '../../store/auth/auth.actions';
import { APP_LANGUAGES } from '../../constants';
import { EStorageType } from '../../data-types/storage.enum';

@Component({
  selector: 'backoffice-header',
  templateUrl: './backoffice-header.component.html',
  styleUrls: ['./backoffice-header.component.scss']
})
export class BackofficeHeaderComponent implements OnInit {

  user: any;
  userLogged: boolean = false;
  buttonStyles = EButtonStyles;
  currentRoute: string = "";
  languages = APP_LANGUAGES;
  currentLanguage: any
  isLoading: boolean = false;

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    this.currentLanguage = localStorage.getItem(EStorageType.Language)
    if (this.currentLanguage == null) {
      this.currentLanguage = "es";
    }
  }
  onLogout() {
    this.store.dispatch(logoutAction())
  }


  onSelectLanguage(lang: string) {
    localStorage.setItem(EStorageType.Language, lang);
    window.location.reload();
  }

}
