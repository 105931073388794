export const APP_LANGUAGES = [
  'ca',
  'es',
  'fr',
  'en'
]

export const PAGE_SIZE_TABLES = 15;

export const WARNING_ICON = 'assets/img/icons/error-warning.svg';
export const SUCCESS_ICON = 'assets/img/icons/success-check.svg';

export const GLOBAL_MODAL_ID = 'global-modal';

export enum EControlStatus {
  DISABLED = 'disabled',
  ACTIVE = 'active',
  UNABLED = 'unabled'
}

export const EStationControls = {
  MUPI: {
    manual: 'mupiManualControl',
    state: 'mupiActivated'
  },

  OUTDOOR_LIGHT: {
    manual: 'outdoorLightManualControl',
    state: 'outdoorLight'
  },
  INDOOR_LIGHT: {
    manual: 'indoorLightManualControl',
    state: 'indoorLight'
  },
  BATTERY: {
    manual: 'batteryChargeManualControl',
    state: 'ailments'
  }
}

export const EControlSwitchColor = {
  OFF_CONTAINER: 'off-container',
  ON_CONTAINER: 'on-container',
  AUTO_CONTAINER: 'auto-container',
  OFF: 'OFF',
  ON: 'ON',
  AUTO: 'AUTO'
}

export const STATION_TYPE = 'stationType'

export const GROUPING_STATICS = [
  {
    id: 0,
    type: 'STATISTICS.USER_TYPE.COIN',
    isCheked: false
  },
  {
    id: 1,
    type: 'STATISTICS.USER_TYPE.MONTHLY',
    isCheked: false,
  },
  {
    id: 2,
    type: 'STATISTICS.USER_TYPE.ANNUAL',
    isCheked: false
  }
]
export const ROOTS_CLIENTS = ['/stations', '/statistics']

