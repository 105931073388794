import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'backoffice-switch',
  templateUrl: './backoffice-switch.component.html',
  styleUrls: ['./backoffice-switch.component.scss']
})
export class BackofficeSwitchComponent implements OnInit {

  @Input() checked: boolean = false;
  @Input() label: string = '';
  @Input() position: 'before' | 'after' = 'before';
  @Input() disabled: boolean = false;
  @Output() onChangeToggle: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onToggle(ev: any) {
    this.onChangeToggle.emit(ev.checked);
  }

}
