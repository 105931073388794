<backoffice-loading [show]="isLoading"></backoffice-loading>
<div class="header">
  <div class="logo">
    <img src="assets/img/icons/logo-full.svg" />
  </div>
  <span class="separator">
  </span>
  <span class="title">Frabel</span>
  <div class="logout" (click)="onLogout()">
    {{ "LABELS.LOGOUT" | translate }}
  </div>
  <div *ngFor="let lang of languages" class="language">
    <img src="assets/img/icons/{{lang}}.png" alt="" (click)="onSelectLanguage(lang)"
      [ngClass]="{'active' : lang === currentLanguage}">
  </div>
</div>