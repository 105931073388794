<backoffice-loading [show]="isLoading"></backoffice-loading>
<backoffice-modal
[modalId]="globalModalId"
[title]="globalModalInfo.title | translate"
[message]="globalModalInfo.message | translate: {params: globalModalInfo.messageParams}"
[okLabel]="globalModalInfo.okLabel | translate"
[switchText]="globalModalInfo.switchText | translate"
[noLabel]="globalModalInfo.cancelLabel| translate"
(onOkButton)="onOkCallback($event)"
(onNoButton)="onCloseModal()"
(onCloseModal)="onCloseModal()"
></backoffice-modal>
<div class="page">
  <div class="header-wrapper" *ngIf="showHeader">
    <backoffice-header></backoffice-header>
  </div>
  <div class="all_menu">
      <div class="menu-wrapper" *ngIf="showMenu">
        <backoffice-menu></backoffice-menu>
      </div>
     <div class="content">
       <router-outlet></router-outlet>
     </div>
  </div>
</div>
