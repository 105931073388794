import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, finalize, map, Observable, of, switchMap, tap } from "rxjs";
import { GLOBAL_MODAL_ID, WARNING_ICON } from "../../constants";
import { StationsService } from "../../services/stations.service";
import { hideLoading, showLoading } from "../loading/loading.actions";
import { showDialogAction, showInfoModalAction } from "../modal/modal.actions";
import { finishBookingAction, finishBookingConfirmAction, getBoxesAction, getBoxesSuccessAction, getStationType, getStationTypeSuccess, openBoxAction, openBoxConfirmAction, resetStationAction, resetStationActionConfim } from "./boxes.actions";
import { updateBoxSelectedStationAction } from "../stations/stations.actions";

@Injectable({
  providedIn: 'root'
})


export class BoxesEffects {
  private _actions = this.injector.get(Actions);
  private _store = this.injector.get(Store);
  private _stationService = this.injector.get(StationsService);

  constructor(
    private injector: Injector
  ) { }
  oginite(): void {



  }

  getBoxes$ = createEffect(() =>
    this._actions.pipe(
      ofType(getBoxesAction),
      switchMap((action) => {
        return this._stationService.getBoxes(action.stationId).pipe(
          finalize(() => {
            this._store.dispatch(hideLoading());
          }),
          map((response) => {
            return getBoxesSuccessAction({
              boxesList: response.data.boxes
            })
          }),
          catchError((error) => {
            let info = {
              title: 'ERRORS.TITLES.GENERAL',
              message: error.message,
              icon: WARNING_ICON,
              okLabel: 'LABELS.OK',
            }
            return this.handleError(error, info);
          })
        )
      })
    )
  )


  openBox$ = createEffect(() =>
    this._actions.pipe(
      ofType(openBoxAction),
      switchMap((action) => {
        let info = {
          modalId: GLOBAL_MODAL_ID,
          title: 'BOX.OPEN_BOX.TITLE',
          message: 'BOX.OPEN_BOX.MESSAGE',
          okLabel: 'LABELS.OK',
          cancelLabel: 'LABELS.CANCEL',
          type: action.type,
          params: {
            stationId: action.stationId,
            boxId: action.boxId
          }
        };
        return of(
          showDialogAction({
            info: info
          })
        )
      })
    )
  );

  openBoxConfirm$ = createEffect(() =>
    this._actions.pipe(
      ofType(openBoxConfirmAction),
      switchMap((action) => {
        return this._stationService.openBox(action.stationId, action.boxId).pipe(
          finalize(() => {

            this._store.dispatch(hideLoading());
          }),
          map(() => {
            return showInfoModalAction({
              info: {
                modalId: GLOBAL_MODAL_ID,
                title: 'BOX.OPEN_BOX.SUCCESS_TITLE',
                okLabel: 'LABELS.OK',
                type: action.type
              }
            })
          }),
          catchError((error) => {
            let info = {
              title: 'ERRORS.TITLES.GENERAL',
              message: error.message,
              icon: WARNING_ICON,
              okLabel: 'LABELS.OK',
            }
            return this.handleError(error, info);
          })
        )
      })
    )
  );


  finishBooking$ = createEffect(() =>
    this._actions.pipe(
      ofType(finishBookingAction),
      switchMap((action) => {
        let info = {
          modalId: GLOBAL_MODAL_ID,
          title: 'BOX.FINISH_BOOKING.TITLE',
          message: 'BOX.FINISH_BOOKING.MESSAGE',
          switchText: 'BOX.FINISH_BOOKING.PENALTY',
          okLabel: 'LABELS.OK',
          cancelLabel: 'LABELS.CANCEL',
          type: action.type,
          params: {
            stationId: action.stationId,
            boxId: action.boxId
          }
        };
        return of(
          showDialogAction({
            info: info
          })
        )
      })
    )
  );

  finishBookingConfirm$ = createEffect(() =>
    this._actions.pipe(
      ofType(finishBookingConfirmAction),
      switchMap((action) => {
        let body = { applyCharge: action.applyPenalty }
        return this._stationService.finishBooking(action.stationId, action.boxId, body).pipe(
          finalize(() => {
            this._store.dispatch(hideLoading());
          }),
          map(() => {
            this._store.dispatch(updateBoxSelectedStationAction(
              {
                box: {
                  id: action.boxId,
                }
              }
            ));
            return showInfoModalAction({
              info: {
                modalId: GLOBAL_MODAL_ID,
                title: 'BOX.FINISH_BOOKING.SUCCESS_TITLE',
                okLabel: 'LABELS.OK',
                type: action.type
              }
            })
          }),
          catchError((error) => {
            let info = {
              title: 'ERRORS.TITLES.GENERAL',
              message: error.message,
              icon: WARNING_ICON,
              okLabel: 'LABELS.OK',
            }
            return this.handleError(error, info);
          })
        )
      })
    )
  );



  resetStation$ = createEffect(() =>
    this._actions.pipe(
      ofType(resetStationAction),
      switchMap((action) => {
        const info = {
          modalId: GLOBAL_MODAL_ID,
          title: 'STATIONS.RESET_ALARM',
          message: 'STATIONS.SURE_RESET_ALARM',
          okLabel: 'LABELS.OK',
          cancelLabel: 'LABELS.CANCEL',
          type: action.type,
          params: {
            stationId: action.stationId,
            body: action.body
          }
        };
        return of(showDialogAction({ info }));
      })
    )
  );

  resetStationConfirm$ = createEffect(() =>
    this._actions.pipe(
      ofType(resetStationActionConfim),
      switchMap((action) => {
        return this._stationService.resetStation(action.stationId, action.body).pipe(
          switchMap(() => {
            const info = {
              modalId: GLOBAL_MODAL_ID,
              title: 'STATIONS.RESET_ALARM',
              message: 'STATIONS.RESET_DONE',
              okLabel: 'LABELS.OK',
              type: action.type,
            };
            return of(showDialogAction({ info }));
          }),
          catchError((error) => {
            const info = {
              title: 'ERRORS.TITLES.GENERAL',
              message: error.message,
              icon: WARNING_ICON,
              okLabel: 'LABELS.OK',
            };
            return this.handleError(error, info);
          }),
          finalize(() => {
            this._store.dispatch(hideLoading());
            window.location.reload();
          })
        );
      })
    )
  );


  getTypeOfStations$ = createEffect(() =>
    this._actions.pipe(
      ofType(getStationType),
      switchMap(() => {
        return this._stationService.getTypeOfStations().pipe(
          map((response: any) => {
            return getStationTypeSuccess({ stationTypes: response.data.stationTypes });
          }),
        );
      })
    )
  );





  private handleError(response: HttpErrorResponse, info: any): Observable<any> {
    let modalInfo = {
      ...info,
      show: true,
      modalId: GLOBAL_MODAL_ID,
      message: response.error ? response.error.message : response.error
    };
    return of(
      this._store.dispatch(showInfoModalAction({ info: modalInfo }))
    );
  }

}
