import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BackofficeHeaderModule } from './shared/components/backoffice-header/backoffice-header.module';
import { BackofficeLoadingModule } from './shared/components/backoffice-loading/backoffice-loading.module';
import { BackofficeMenuModule } from './shared/components/backoffice-menu/backoffice-menu.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Interceptor } from './shared/interceptor/interceptor';
import { appReducers } from './shared/store/app.reducer';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { IAppState } from './shared/store/app.state';
import { storeLogger } from './shared/store/logger.reducer';
import { EffectsModule } from '@ngrx/effects';
import { appEffects } from './shared/store/app.effects';
import { BackofficeModalModule } from './shared/components/backoffice-modal/backoffice-modal.module';
import { MatTableModule } from '@angular/material/table';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule, NativeDateAdapter } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
// import {
//   BarController,
//   BarElement,
//   Chart,
//   CategoryScale,
//   LinearScale,
//   Title,
//   Tooltip,
//   Legend,
//   ArcElement
// } from 'chart.js';
// Chart.register(ArcElement, BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip, Legend);
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

export function logger(reducer: ActionReducer<IAppState>): any {
  return storeLogger()(reducer);
}

export const metaReducers: MetaReducer<IAppState>[] = [logger];

class CustomDateAdapter extends NativeDateAdapter {
  getFirstDayOfWeek(): number {
    return 1;
  }
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSelectModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot(appReducers, {
      metaReducers, //logs redux actions
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: true,
        strictActionSerializability: false
      }
    }),
    EffectsModule.forRoot(appEffects),
    BackofficeHeaderModule,
    BackofficeMenuModule,
    BackofficeLoadingModule,
    BackofficeModalModule,
    MatTableModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    OverlayModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'ca-CA' },
    [{ provide: DateAdapter, useClass: CustomDateAdapter }]
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
