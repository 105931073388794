import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private API_URL = environment.API_URL;

  updateUsers: Subject<void> = new Subject();

  constructor(
    private http: HttpClient
  ) { }

  getUsers(queryFilter: string): Observable<any> {
    return this.http.get(`${this.API_URL}/cms/users` + queryFilter);
  }

  getUserDetails(id: string): Observable<any> {
    return this.http.get(`${this.API_URL}/cms/users/${id}`);
  }

  createUser(body: any): Observable<any> {
    return this.http.post(`${this.API_URL}/cms/users`, body);
  }

  editUser(body: any, userId: number): Observable<any> {
    return this.http.patch(`${this.API_URL}/cms/users/${userId}`, body)
  }

  deleteUser(userId: number): Observable<any> {
    return this.http.delete(`${this.API_URL}/cms/users/${userId}`);
  }
}
