import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { PAGE_SIZE_TABLES } from 'src/app/shared/constants';
import { EDataTypes } from 'src/app/shared/data-types/data_types.enum';
import { IAppState } from 'src/app/shared/store/app.state';
import { getAlarmIncidencesAction, getChargeIncidencesAction, getDoorIncidencesAction } from 'src/app/shared/store/box-incidences/box-incidences.actions';
import { selectAlarmIncidencesError, selectAlarmIncidencesListState, selectAlarmIncidencesTotalPages, selectChargeIncidencesError, selectChargeIncidencesListState, selectChargeIncidencesTotalPages, selectDoorIncidencesError, selectDoorIncidencesListState, selectDoorIncidencesTotalPages } from 'src/app/shared/store/box-incidences/box-incidences.selector';
import { selectBookingIncidencesListState, selectBookingIncidencesTotalPages } from 'src/app/shared/store/booking-incidences/booking-incidences.selector';
import { showLoading } from 'src/app/shared/store/loading/loading.actions';
import { getBookingIncidencesAction } from 'src/app/shared/store/booking-incidences/booking-incidences.actions';
import { formatDate } from '@angular/common';
import { selectStationsListStateStations, selectStationsListStateTotalPages } from 'src/app/shared/store/stations/stations.selector';
import { getStationsAction } from 'src/app/shared/store/stations/stations.actions';


@Component({
  selector: 'app-incidences',
  templateUrl: './incidences.component.html',
  styleUrls: ['./incidences.component.scss']
})
export class IncidencesComponent implements OnInit {

  EDataTypes = EDataTypes;
  activeTab: number = 0;
  tabs: any = [];
  chargeIncidencesloading: boolean
  doorIncidencesloading: boolean
  bookingIncidencesloading: boolean
  alarmIncidencesloading: boolean
  getStationsActionloading: boolean

  chargeIncidencesError: boolean
  doorIncidencesError: boolean
  bookingIncidencesError: boolean
  alarmIncidencesError: boolean

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<IAppState>,
    private translate: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    let currDate = new Date();
    let tomorrowDate = new Date();
    tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    this.tabs = [
      {
        label: this.translate.instant('INCIDENCES.ALARM'),
        totalPages: 0,
        currentPage: 0,
        totalPages$: this.store.select(selectAlarmIncidencesTotalPages),
        list$: this.store.select(selectAlarmIncidencesListState),
        fromDate: currDate,
        untilDate: tomorrowDate,
        tableData: {
          headers: [
            {
              title: 'INCIDENCES.TYPE',
              key: 'alarmText',
              class: 'align-left',
              type: EDataTypes.STRING
            },
            {
              title: 'STATIONS.HEADERS.STATION_ID',
              key: 'stationName',
              class: 'align-left clickable',
              onClick: (row: any) => { this.router.navigate([`stations/edit/${row.stationId}`]) },
              type: EDataTypes.NUMBER
            },
            {
              title: 'BOX.HEADERS.ID',
              key: 'box',
              class: 'align-left',
              type: EDataTypes.STRING
            },
            {
              title: 'INCIDENCES.DATE',
              key: 'date',
              class: 'align-left',
              type: EDataTypes.DATE
            },
            {
              title: 'BOX.HEADERS.ALARM',
              key: 'alarmActive',
              class: 'align-left',
              type: EDataTypes.BOOLEAN
            },
          ],
          rows: []
        }
      },
      {
        label: this.translate.instant('INCIDENCES.DOOR'),
        totalPages: 0,
        currentPage: 0,
        totalPages$: this.store.select(selectDoorIncidencesTotalPages),
        list$: this.store.select(selectDoorIncidencesListState),
        fromDate: currDate,
        untilDate: tomorrowDate,
        tableData: {
          headers: [
            {
              title: 'INCIDENCES.TYPE',
              key: 'alarmText',
              class: 'align-left',
              type: EDataTypes.STRING
            },
            {
              title: 'STATIONS.HEADERS.STATION_ID',
              key: 'stationName',
              onClick: (row: any) => { this.router.navigate([`stations/edit/${row.stationId}`]) },
              class: 'align-left clickable',
              type: EDataTypes.NUMBER
            },
            {
              title: 'BOX.HEADERS.ID',
              key: 'box',
              class: 'align-left',
              type: EDataTypes.STRING
            },
            {
              title: 'INCIDENCES.DATE',
              key: 'date',
              class: 'align-left',
              type: EDataTypes.DATE
            },
            {
              title: 'BOX.HEADERS.ALARM',
              key: 'alarmActive',
              class: 'align-left',
              type: EDataTypes.BOOLEAN
            },
          ],
          rows: []
        }
      },
      {
        label: this.translate.instant('INCIDENCES.CHARGE'),
        totalPages: 0,
        currentPage: 0,
        totalPages$: this.store.select(selectChargeIncidencesTotalPages),
        list$: this.store.select(selectChargeIncidencesListState),
        fromDate: currDate,
        untilDate: tomorrowDate,
        tableData: {
          headers: [
            {
              title: 'INCIDENCES.TYPE',
              key: 'alarmText',
              class: 'align-left',
              type: EDataTypes.NUMBER
            },
            {
              title: 'STATIONS.HEADERS.STATION_ID',
              key: 'stationName',
              onClick: (row: any) => { this.router.navigate([`stations/edit/${row.stationId}`]) },
              class: 'align-left clickable',
              type: EDataTypes.NUMBER
            },
            {
              title: 'BOX.HEADERS.ID',
              key: 'box',
              class: 'align-left',
              type: EDataTypes.STRING
            },
            {
              title: 'INCIDENCES.DATE',
              key: 'date',
              class: 'align-left',
              type: EDataTypes.DATE
            },
            {
              title: 'BOX.HEADERS.ALARM',
              key: 'alarmActive',
              class: 'align-left',
              type: EDataTypes.BOOLEAN
            },
          ],
          rows: []
        }
      },
      {
        label: this.translate.instant('INCIDENCES.PENALTY'),
        totalPages: 0,
        currentPage: 0,
        totalPages$: this.store.select(selectBookingIncidencesTotalPages),
        list$: this.store.select(selectBookingIncidencesListState),
        fromDate: currDate,
        untilDate: tomorrowDate,
        tableData: {
          headers: [
            {
              title: 'BOX.HEADERS.ASSIGNED_TO',
              key: 'assignedTo',
              class: 'align-left clickable',
              onClick: (row: any) => { this.router.navigate([`users/edit/${row.assignedTo}`]) },
              type: EDataTypes.NUMBER
            },
            {
              title: 'STATIONS.HEADERS.STATION_ID',
              key: 'stationName',
              class: 'align-left clickable',
              onClick: (row: any) => { this.router.navigate([`stations/edit/${row.stationId}`]) },
              type: EDataTypes.NUMBER
            },
            {
              title: 'BOX.HEADERS.ID',
              key: 'boxName',
              class: 'align-left',
              type: EDataTypes.STRING
            },
            {
              title: 'BOOKINGS.HEADERS.DURATION',
              key: 'durationMinutes',
              class: 'align-left',
              type: EDataTypes.NUMBER
            },
            {
              title: 'BOOKINGS.HEADERS.START_DATE',
              key: 'confirmedDate',
              class: 'align-left',
              type: EDataTypes.STRING
            },
            {
              title: 'BOOKINGS.HEADERS.FINISH_DATE',
              key: 'finishDate',
              class: 'align-left',
              type: EDataTypes.STRING
            },
          ],
          rows: []
        }
      },
      {
        label: this.translate.instant('INCIDENCES.MUPI'),
        totalPages: 0,
        currentPage: 0,
        totalPages$: this.store.select(selectStationsListStateTotalPages),
        list$: this.store.select(selectStationsListStateStations),
        fromDate: currDate,
        untilDate: tomorrowDate,
        tableData: {
          headers: [
            {
              title: 'STATIONS.HEADERS.STATION_ID',
              key: 'stationId',
              class: 'align-left clickable',
              onClick: (row: any) => { this.router.navigate([`stations/edit/${row.id}`]) },
              type: EDataTypes.NUMBER
            },
            {
              title: 'STATIONS.HEADERS.ACTIVE',
              key: 'active',
              class: 'align-left',
              type: EDataTypes.STRING
            },
            {
              title: 'STATIONS.HEADERS.OWNER',
              key: 'owner',
              class: 'align-left',
              type: EDataTypes.STRING
            },
            {
              title: 'STATIONS.HEADERS.CITY',
              key: 'city',
              class: 'align-left',
              type: EDataTypes.STRING
            },
            {
              title: 'STATIONS.HEADERS.TOTAL_BOXES',
              key: 'totalBoxes',
              type: EDataTypes.NUMBER
            },
            {
              title: 'INCIDENCES.MUPI',
              key: 'mupiConnected',
              class: 'align-left',
              type: EDataTypes.STRING
            },
            {
              title: '',
              key: 'mupiActivated',
              class: 'align-left',
              type: EDataTypes.STRING
            },
          ],
          rows: []
        }
      },
    ]

    this.tabs.forEach((tab: any, i: number) => {
      tab.totalPages$.pipe(takeUntil(this.destroy$)).subscribe((response: any) => {
        tab.totalPages = response;
      });
      tab.list$.pipe((takeUntil(this.destroy$))).subscribe((response: any) => {


        if (i < 3) {
          tab.tableData.rows = response.map(row => ({
            ...row,
            box: row.box.name,
            alarmActive: row.box.alarm ? this.translate.instant('LABELS.YES') : this.translate.instant('LABELS.NO')
          }));
        } else if (i === 3) {
          tab.tableData.rows = response.map(row => ({
            ...row,
            durationMinutes: this.formatDuration(row.durationMinutes),
            confirmedDate: formatDate(new Date(row.confirmedDate), 'dd/MM/YYYY HH:mm', 'es-ES'),
            finishDate: row.finishDate ? formatDate(new Date(row.confirmedDate), 'dd/MM/YYYY HH:mm', 'es-ES') : '-',
          }));
        } else {

          tab.tableData.rows = response.map((station) => ({

            ...station,
            active: station.active ? this.translate.instant('LABELS.YES') : this.translate.instant('LABELS.NO'),
            mupiConnected: station.mupiConnected ? this.translate.instant('STATIONS.CREATE.MUPI_CONNECTED') : this.translate.instant('STATIONS.CREATE.MUPI_DISCONNECTED'),
            mupiActivated: station.mupiActivated ? this.translate.instant('STATIONS.CREATE.MUPI_ACTIVE') : this.translate.instant('STATIONS.CREATE.MUPI_INACTIVE'),
          }));
        }
      })
      this.fetchData(i);
    })
  }

  onPreviousPage() {
    this.tabs[this.activeTab].currentPage -= 1;
    this.fetchData(this.activeTab);
  }

  onNextPage() {
    this.tabs[this.activeTab].currentPage += 1;
    this.fetchData(this.activeTab);
  }

  fetchData(i: number) {
    switch (i) {
      case 0: {
        this.store.dispatch(getAlarmIncidencesAction({ queryFilter: this.getQueryFilters() }));
        this.store.pipe(select(state => state.alarmIncidences.isLoading)).subscribe(loading => {
          this.alarmIncidencesloading = loading;
        });
        this.store.pipe(select(selectAlarmIncidencesError)).subscribe(error => {
          if (error) {
            this.alarmIncidencesError = true;
          }
        });
        break;
      }
      case 1: {
        this.store.dispatch(getDoorIncidencesAction({ queryFilter: this.getQueryFilters() }));
        this.store.pipe(select(state => state.doorIncidences.isLoading)).subscribe(loading => {
          this.doorIncidencesloading = loading;
        });
        this.store.pipe(select(selectDoorIncidencesError)).subscribe(error => {
          if (error) {
            this.doorIncidencesloading = true;
          }
        });
        break;
      }
      case 2: {
        this.store.dispatch(getChargeIncidencesAction({ queryFilter: this.getQueryFilters() }));
        this.store.pipe(select(state => state.chargeIncidences.isLoading)).subscribe(loading => {
          this.chargeIncidencesloading = loading;
        });
        this.store.pipe(select(selectChargeIncidencesError)).subscribe(error => {
          if (error) {
            this.chargeIncidencesError = true;
          }
        });
        break;
      }
      case 3: {
        this.store.dispatch(getBookingIncidencesAction({ queryFilter: this.getQueryFilters() }));
        this.store.pipe(select(state => state.bookingIncidences.isLoading)).subscribe(loading => {
          this.bookingIncidencesloading = loading;
        });
        break;
      }
      case 4: {
        this.store.dispatch(getStationsAction({ queryFilters: this.getQueryFilters() }));
        this.store.pipe(select(state => state.stations.isLoading)).subscribe(loading => {
          this.bookingIncidencesloading = loading;
        });
        break;
      }
    }
  }

  getQueryFilters() {
    let query = `?page=${this.tabs[this.activeTab].currentPage}&pageSize=${PAGE_SIZE_TABLES}`
    const fromDate = new Date(this.tabs[this.activeTab].fromDate);
    const untilDate = new Date(this.tabs[this.activeTab].untilDate);
    query += `&fromDate=${fromDate.toISOString().split('T')[0]}&untilDate=${untilDate.toISOString().split('T')[0]}`;
    return query;
  }

  onDateChange(event: any) {
    this.tabs[this.activeTab].fromDate = new Date(event.start);
    this.tabs[this.activeTab].untilDate = new Date(event.end);
    this.fetchData(this.activeTab);
  }

  private formatDuration(duration) {
    if (!duration) return '-';
    let durationString = '';
    let hours = Math.floor(duration / 60);
    let minutes = duration % 60;
    if (hours > 0) durationString = `${hours}h`;
    if (hours > 0 && minutes > 0) durationString += ' ';
    if (minutes > 0) durationString += `${minutes}min`;
    return durationString;
  }
}
