<div class="login">
  <div class="header-wrapper">
    <div class="header">
      <div class="logo">
        <img class="logo-image" src="assets/img/icons/logo-full.svg"/>
      </div>
    </div>
  </div>
  <div class="login-wrapper">
    <div class = "strip-container">
      <div class="login-title">
        {{ "WELCOME.TITLE" | translate }}
      </div>
      <div class ="login-subtitle">
        {{ "WELCOME.SUBTITLE" | translate }}
      </div>
      <div class="err">
        <img class="image-error"
          src="assets/img/icons/ic-info-red.svg"
          alt="info-red"
          class="info-red"
          *ngIf="statusError && statusError > 400"
        />
        <div *ngIf="statusError">
          {{ errorMessage }}
        </div>
    </div>
      <div class="login-content">
        <form class = "form" [formGroup]="loginForm">
          <backoffice-input
            [label]="'LOGIN.EMAIL' | translate"
            [isForm]="true"
            [control]="getControl('email')"
          ></backoffice-input>
          <backoffice-input
            [label]="'LOGIN.PASSWORD' | translate"
            [isForm]="true"
            [control]="getControl('password')"
            [type]="'password'"
          ></backoffice-input>
          <backoffice-button
            [label]="'LABELS.SIGNIN' | translate"
            [disabled]="!loginForm.valid"
            (onClick)="onLogin()"
            [style]="EButtonStyles.WIDE"
          ></backoffice-button>
        </form>
      </div>
    </div>
  </div>
</div>
