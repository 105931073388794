export const ACTION_LOGIN = '[Auth] login';
export const ACTION_LOGIN_SUCCESS = '[Auth] login success';
export const ACTION_LOGIN_ERROR = '[Auth] login error';
export const ACTION_LOGOUT = '[Auth] open logout modal';
export const ACTION_LOGOUT_CONFIRM = '[Auth] logout';

export const ACTION_SHOW_LOADING = '[Loading] Show Loading';
export const ACTION_HIDE_LOADING = '[Loading] Hide Loading';

export const ACTION_SHOW_DIALOG_MODAL = '[Modal] show dialog modal';
export const ACTION_SHOW_INFO_MODAL = '[Modal] show info modal';
export const ACTION_OPEN_MODAL = '[Modal] open modal';
export const ACTION_OPEN_MODAL_SUCCESS = '[Modal] open modal success';
export const ACTION_HIDE_MODAL = '[Modal] hide modal';
export const ACTION_HIDE_MODAL_SUCCESS = '[Modal] hide modal success';

export const ACTION_GET_USERS = '[Users] get users';
export const ACTION_GET_USERS_SUCCESS = '[Users] get users success';

export const ACTION_CREATE_USER = '[Users] create user';
export const ACTION_CREATE_USER_SUCCESS = '[Users] create user success';

export const ACTION_DELETE_USER = '[Users] delete user';
export const ACTION_DELETE_USER_CONFIRM = '[Users] delete user confirm';
export const ACTION_DELETE_USER_SUCCESS = '[Users] delte user success';

export const ACTION_EDIT_SELECT_USER = '[Users] select user to edit';
export const ACTION_EDIT_USER = '[Users] edit user';
export const ACTION_EDIT_USER_SUCCESS = '[Users] edit user success';

export const ACTION_EXPORT_USERS = '[Users] export users';
export const ACTION_EXPORT_USERS_CONFIRM = '[Users] export users confirm';
export const ACTION_EXPORT_USERS_SUCCESS = '[Users] export users success';

export const ACTION_GET_STATIONS = '[Stations] get stations';
export const ACTION_GET_STATIONS_SUCCESS = '[Stations] get stations success';

export const ACTION_CREATE_STATION = '[Stations] create station';
export const ACTION_CREATE_STATION_SUCCESS = '[Stations] create station success';

export const ACTION_EDIT_SELECT_STATION = '[Stations] select station to edit';
export const ACTION_EDIT_STATION = '[Stations] edit station';
export const ACTION_EDIT_STATION_SUCCESS = '[STATIONs] edit station success';
export const ACTION_UPDATE_BOX_SELECTED_STATION = '[Boxes] update box selected station'

export const ACTION_DELETE_STATION = '[Stations] delete station';
export const ACTION_DELETE_STATION_CONFIRM = '[Stations] delete station confirm';
export const ACTION_DELETE_STATION_SUCCESS = '[Stations] delete station success';

export const ACTION_GET_BOXES = '[Boxes] get boxes';
export const ACTION_GET_BOXES_SUCCESS = '[Boxes] get boxes success';

export const ACTION_OPEN_BOX = '[Boxes] open box';
export const ACTION_OPEN_BOX_CONFIRM = '[Boxes] open box confirm';

export const ACTION_FINISH_BOOKING = '[Boxes] finish booking';
export const ACTION_FINISH_BOOKING_CONFIRM = '[Boxes] finish booking confirm';

export const ACTION_GET_ALARM_INCIDENCES = '[Incidences] get alarm incidences';
export const ACTION_GET_ALARM_INCIDENCES_SUCCESS = '[Incidences] get alarm incidences success';

export const ACTION_GET_DOOR_INCIDENCES = '[Incidences] get door incidences';
export const ACTION_GET_DOOR_INCIDENCES_SUCCESS = '[Incidences] get door incidences success';

export const ACTION_GET_CHARGE_INCIDENCES = '[Incidences] get charge incidences';
export const ACTION_GET_CHARGE_INCIDENCES_SUCCESS = '[Incidences] get charge incidences success';

export const ACTION_GET_BOOKING_INCIDENCES = '[Incidences] get booking incidences';
export const ACTION_GET_BOOKING_INCIDENCES_SUCCESS = '[Incidences] get booking incidences success';

export const ACTION_RESET_STATION = '[Station] Reset Station';
export const ACTION_RESET_STATION_CONFIRM = '[Station] Reset Station Confirm';

export const ACTION_EXPORT_STATIONS = '[Stations] export stations';
export const ACTION_EXPORT_STATIONS_CONFIRM = '[Stations] export stations confirm';

export const ACTION_UPDATE_STATION_ALARM = '[Station] Update alarm station';

export const ACTION_GET_STATIONS_TYPE = '[Station] get stations types'
export const ACTION_GET_STATIONS_TYPE_SUCCESS = '[Station] get stations types success'


export const ACTION_CREATE_CLIENTS = '[Clients] create client'
export const ACTION_CREATE_CLIENTS_SUCCESS = '[Clients] create client success';

export const ACTION_GET_CLIENTS = '[Clients] get client';
export const ACTION_GET_CLIENTS_SUCCESS = '[Clients] get client success';

export const ACTION_DELETE_CLIENTS = '[Clients] delete clients';
export const ACTION_DELETE_CLIENTS_CONFIRM = '[Clients] delete clients confirm';
export const ACTION_DELETE_CLIENTS_SUCCESS = '[Clients] delte clients success';

export const ACTION_EDIT_SELECT_CLIENT = '[Clients] select client to edit';
export const ACTION_EDIT_CLIENTS = '[Clients] edit client';
export const ACTION_EDIT_CLIENTS_SUCCESS = '[Clients] edit client success';

export const ACTION_EXPORT_CLIENTS = '[Clients] export client';
export const ACTION_EXPORT_CLIENTS_CONFIRM = '[Clients] export client confirm';
export const ACTION_EXPORT_CLIENTS_SUCCESS = '[Clients] export client success';

export const ACTION_EXPORT_STATISTICS_NUMBER_OF_USERS = '[Statistics] export statistics number of users';
export const ACTION_EXPORT_STATISTICS_TIME = '[Statistics] export statistics time of use';

export const ACTION_EXPORT_STATISTICS_NATIONALITY = '[Statistics] export statistics nationality';
export const ACTION_EXPORT_STATISTICS_TYPES_OF_USERS = '[Statistics] export statistics types of users';

export const ACTION_EXPORT_STATISTICS_CONFIRM = '[Statistics] export statistics confirm';
export const ACTION_EXPORT_STATISTICS_SUCCESS = '[Statistics] export statistics success';

export const ACTION_GET_INCIDENCES_ALARM_FAILURE = '[Incidences] get alarm incidences failure'
export const ACTION_GET_INCIDENCES_DOOR_FAILURE = '[Incidences] get door incidences failure'
export const ACTION_GET_INCIDENCES_CHARGE_FAILURE = '[Incidences] get charge incidences failure'