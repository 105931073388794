<mat-drawer-container class="container-statics" autosize>
  <mat-drawer #drawer class="side-bar" mode="side" opened="true" (openedChange)="drawerOpened()">
    <backoffice-select-statics (showStaticsType0)=" formatDataSet0($event)" (showStaticsType1)="formatDataSet1($event)"
      (showStaticsType2)="formatDataSet2($event)" (showStaticsType3)="formatDataSet3($event)"
      (statisticsLoading)="statisticsIsLoading($event)"></backoffice-select-statics>
  </mat-drawer>
  <div class="static-stations">
    <img [src]="drawer.opened ?  'assets/img/icons/arrow-left.svg' : 'assets/img/icons/arrow-right.svg' " alt=""
      (click)="drawer.toggle()" class="button-toggle">
    <div class="statics">
      <div class="statics-box" [ngClass]="{ 'smaller-div': smallerContainer }">
        <div class="statistics-wrapper">
          <span>{{ ("STATISTICS.NUMBER_OF_USERS_CONTRACT_SERVICE") | translate }}:
            <img *ngIf="dataSet0.length !== 0" (click)="exportStatics0()"
              src='assets/img/icons/ic-export-all.svg'></span>
          <div class="canvas" [ngClass]="{ 'no-data-show':dataSet0.length === 0 || loadingMetrics.metric0 }">
            <backoffice-statics-linear [id]="'linearChart0'" [dataset0]="dataSet0"></backoffice-statics-linear>
          </div>
          <div *ngIf="loadingMetrics.metric0" class="no-data">
            <mat-spinner [diameter]="100"></mat-spinner>
          </div>
          <div *ngIf="dataSet0.length == 0 && !loadingMetrics.metric0" class="no-data">
            <span>
              {{ ("STATISTICS.SELECT_FILTERS_STATISTICS") | translate
              }} </span>
          </div>
          <div *ngIf="dataSet0.noData" class="no-data">
            <span>
              {{ ("STATISTICS.NO_DATA") | translate
              }} </span>
          </div>
        </div>

      </div>
      <div class="statics-box" [ngClass]="{ 'smaller-div': smallerContainer }">
        <div class="statistics-wrapper">
          <span>{{ ("STATISTICS.TIME_USERS_CONTRACTED_SERVICE") | translate
            }}:
            <img *ngIf="dataSet1.length !== 0 " class="top-bar-export-icon" (click)="exportStatics1()"
              src='assets/img/icons/ic-export-all.svg'>
          </span>
          <div class="canvas" [ngClass]="{ 'no-data-show': dataSet1.length == 0 || loadingMetrics.metric1 }">
            <backoffice-statics-linear [id]="'linearChart1'" [dataset1]="dataSet1"></backoffice-statics-linear>

          </div>
          <div *ngIf="loadingMetrics.metric1" class="no-data">
            <mat-spinner [diameter]="100"></mat-spinner>
          </div>
          <div *ngIf="dataSet1.length == 0 && !loadingMetrics.metric1" class="no-data">
            <span>
              {{ ("STATISTICS.SELECT_FILTERS_STATISTICS") | translate
              }} </span>
          </div>
          <div *ngIf="dataSet1.noData" class="no-data">
            <span>
              {{ ("STATISTICS.NO_DATA") | translate
              }} </span>
          </div>
        </div>

      </div>
      <div class="statics-box" [ngClass]="{ 'smaller-div': smallerContainer }">
        <div class="statistics-wrapper">
          <span>{{ ("STATISTICS.NATIONALITY_USERS") | translate
            }}:
            <img *ngIf="dataSet2.length !== 0 " (click)="exportStatics2()" class="top-bar-export-icon"
              src='assets/img/icons/ic-export-all.svg'>
          </span>

          <div class="canvas" [ngClass]="{ 'no-data-show': dataSet2.length == 0  || loadingMetrics.metric2}">
            <backoffice-statics-doughnut [dataset2]="dataSet2" [id]="'doughnutChart2'"></backoffice-statics-doughnut>
          </div>
          <div *ngIf="loadingMetrics.metric2" class="no-data">
            <mat-spinner [diameter]="100"></mat-spinner>
          </div>
          <div *ngIf="dataSet2.length == 0 && !loadingMetrics.metric2" class="no-data">
            <span *ngIf="!dataSet2.noData">
              {{ ("STATISTICS.SELECT_FILTERS_STATISTICS") | translate
              }} </span>
          </div>
          <div *ngIf="dataSet2.noData" class="no-data">
            <span>
              {{ ("STATISTICS.NO_DATA") | translate
              }} </span>
          </div>
        </div>

      </div>
      <div class="statics-box" [ngClass]="{ 'smaller-div': smallerContainer }">
        <div class="statistics-wrapper">
          <span>{{ ("STATISTICS.USERS_TYPE") | translate
            }}:
            <img *ngIf="dataSet3.length !== 0 " (click)="exportStatics3()" class="top-bar-export-icon"
              src='assets/img/icons/ic-export-all.svg'>
          </span>
          <div class="canvas" [ngClass]="{ 'no-data-show': dataSet3.length == 0  || loadingMetrics.metric3}">
            <backoffice-statics-doughnut [dataset3]="dataSet3" [id]="'doughnutChart3'"></backoffice-statics-doughnut>

          </div>
          <div *ngIf="loadingMetrics.metric3" class="no-data">
            <mat-spinner [diameter]="100"></mat-spinner>
          </div>
          <div *ngIf="dataSet3.length == 0 && !loadingMetrics.metric3" class="no-data">
            <span>
              {{ ("STATISTICS.SELECT_FILTERS_STATISTICS") | translate
              }}
            </span>
          </div>
          <div *ngIf="dataSet3.noData" class="no-data">
            <span>
              {{ ("STATISTICS.NO_DATA") | translate
              }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-drawer-container>