
import { createSelector } from '@ngrx/store';
import { IAppState } from '../app.state';
import { ILoadingState } from './loading.state';

const selectLoading = (state: IAppState) => state.loading;

export const selectLoadingState = createSelector(
  selectLoading,
  (state: ILoadingState) => state
);
