import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { PAGE_SIZE_TABLES } from "../constants";

@Injectable({
  providedIn: 'root'
})
export class IncidencesService {
  private API_URL = environment.API_URL;

  constructor(
    private http: HttpClient
  ) { }



  getIncidences(type: number, queryFilter: string): Observable<any> {
    const url = `${this.API_URL}/cms/incidences/boxs${queryFilter}&type=${type}`;
    return this.http.get(url);
  }

  getBookingIncidences(queryFilter: string): Observable<any> {
    const url = `${this.API_URL}/cms/incidences/bookings${queryFilter}`
    return this.http.get(url);
  }

}
