import { createAction, props } from '@ngrx/store';
import { ACTION_LOGIN, ACTION_LOGIN_ERROR, ACTION_LOGIN_SUCCESS, ACTION_LOGOUT, ACTION_LOGOUT_CONFIRM } from '../../data-types/actions.enum';
import { IAuthState } from './auth.state';

export const loginAction = createAction(
  ACTION_LOGIN,
  props<{email: string, password: string}>()
)
export const loginSuccessAction = createAction(
  ACTION_LOGIN_SUCCESS,
  props<{user: IAuthState}>()
)
export const loginErrorAction = createAction(
  ACTION_LOGIN_ERROR,
  props<{error: any}>()
)

export const logoutAction = createAction(
  ACTION_LOGOUT
)
export const logoutConfirmAction = createAction(
  ACTION_LOGOUT_CONFIRM
)
