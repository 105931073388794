<div class="content-wrapper">
  <div *ngIf="showTopBar && typeCompoent !== roleClient" class="top-bar">
    <div class="top-bar-item">
      <div class="top-bar-item-search">
        <div class="search-bar">
          <ng-container *ngIf="typeCompoent!=='clients'">
            <input class="search-bar-input" [placeholder]="searchPlaceholder | translate"
              [(ngModel)]="filter.searchText" (keyup)="onSearchKeyPress($event , 'others')">
            <img class="search-bar-icon" src="assets/img/icons/search.svg">
          </ng-container>
        </div>
        <ng-container *ngIf="typeCompoent==='clients'">
          <div class="search-bar-clients-container">
            <div class="search-clients-bar">
              <input class="search-bar-clients-input" [placeholder]="searchCompanyNamePlaceholder| translate"
                [(ngModel)]="filter.companyName" (keyup)="onSearchKeyPress($event, 'companyName')">
              <img class="search-bar-clients-icon" src="assets/img/icons/search.svg">
            </div>
            <div class="search-clients-bar">
              <input class="search-bar-clients-input" [placeholder]="searchNifPlaceholder | translate"
                [(ngModel)]="filter.searchTextNif" (keyup)="onSearchKeyPress($event, 'email')">
              <img class="search-bar-clients-icon" src="assets/img/icons/search.svg">
            </div>
            <div class="search-clients-bar">
              <input class="search-bar-clients-input" [placeholder]="searchIdPlaceholder | translate"
                [(ngModel)]="filter.searchTextId" (keyup)="onSearchKeyPress($event, 'id')">
              <img class="search-bar-clients-icon" src="assets/img/icons/search.svg">
            </div>
          </div>
        </ng-container>
        <backoffice-button class="search-button" [label]="(filtered ? 'LABELS.CANCEL' : 'LABELS.SEARCH') | translate"
          [style]="EButtonStyles.NARROW" (onClick)="onSearch()"
          [disabled]=" isFilterEmpty() && !filtered"></backoffice-button>
      </div>
    </div>
    <div class="top-bar-item">
      <div class="top-bar-item-export" (click)="onExportGlobal()">
        <img class="top-bar-export-icon" src="assets/img/icons/ic-export-all.svg">
      </div>
      <ng-container *ngIf="!isClient">
        <backoffice-button class="create-button" [label]="createLabel | translate" [style]="EButtonStyles.NARROW"
          (onClick)="onCreate()"></backoffice-button>
      </ng-container>

    </div>
  </div>
  <div class="table-wrapper">
    <table class="table">
      <thead>
        <tr>
          <th *ngFor="let header of table?.headers" [ngClass]="header?.class">
            {{ header.title | translate }}
          </th>
          <th *ngIf="hasActions">
            {{ 'LABELS.ACTIONS' | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="table?.rows?.length == 0; else tableRowsTemplate">
        </ng-container>
        <ng-container *ngIf="typeCompoent === 'incidences' ">
          <ng-container>
            <tr *ngIf="activeTab === 0 && alarmIncidencesloading">
              <td colspan="100%" class="loading" *ngIf="!alarmIncidencesError">
                <div class="label-loading">
                  <mat-progress-bar mode="query"></mat-progress-bar>
                </div>
              </td>
              <td colspan="100%" class="loading" *ngIf="alarmIncidencesError">
                <div class="label-loading">
                  {{ 'ERRORS.TABLE_ERROR' | translate }}
                </div>
              </td>
            </tr>
          </ng-container>
          <ng-container>
            <tr *ngIf="activeTab === 1 && doorIncidencesloading">
              <td colspan="100%" class="loading" *ngIf="!doorIncidencesError">
                <div class="label-loading">
                  <mat-progress-bar mode="query"></mat-progress-bar>
                </div>
              </td>
              <td colspan="100%" class="loading" *ngIf="doorIncidencesError">
                <div class="label-loading">
                  {{ 'ERRORS.TABLE_ERROR' | translate }}
                </div>
              </td>
            </tr>
          </ng-container>
          <ng-container>
            <tr *ngIf="activeTab === 2 && chargeIncidencesloading">
              <td colspan="100%" class="loading" *ngIf="!chargeIncidencesError">
                <div class="label-loading">
                  <mat-progress-bar mode="query"></mat-progress-bar>
                </div>
              </td>
              <td colspan="100%" class="loading" *ngIf="chargeIncidencesError">
                <div class="label-loading">
                  {{ 'ERRORS.TABLE_ERROR' | translate }}
                </div>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="activeTab === 3 && bookingIncidencesloading">
            <tr>
              <td colspan="100%" class="loading">
                <div class="label-loading">
                  <mat-progress-bar mode="query"></mat-progress-bar>
                </div>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="activeTab === 4 && getStationsActionloading">
            <tr>
              <td colspan="100%" class="loading">
                <div class="label-loading">
                  <mat-progress-bar mode="query"></mat-progress-bar>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
        <ng-template #tableRowsTemplate>
          <tr class=" table-content" *ngFor="let row of table?.rows">
            <td *ngFor="let header of table?.headers; let i = index" [ngClass]="header?.class"
              [ngClass]="{ 'isCurrent': row?.current }" [ngClass]="{ 'alarmActivated': row?.totalAlerts > 0}"
              (click)="onCellClicked(header.key, row)">
              <ng-container [ngSwitch]="header?.type">
                <ng-container *ngSwitchCase="EDataTypes.DATE">
                  {{ row[header.key] | date: 'dd/MM/YY HH:mm' }}
                </ng-container>
                <ng-container *ngSwitchCase="EDataTypes.PERCENTAGE">
                  <span [ngClass]="row[header.key]?.class">
                    {{ row[header.key].level }} %
                  </span>
                </ng-container>
                <ng-container *ngSwitchCase="EDataTypes.CIRCLE">
                  <div [ngClass]="row[header.key]?.class">
                    <div [ngClass]="getClassForCircle(row[header.key])"></div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ row[header.key] | translate }}
                </ng-container>
              </ng-container>
            </td>
            <backoffice-table-actions [alarmActivated]="row?.totalAlerts> 0" [typeCompoent]="typeCompoent"
              *ngIf="hasActions" (onExport)="allowExport ? onExportRow(row) : null"
              (onEdit)="allowEdit ? onEdit(row) : null" (onDelete)="allowDelete ? onDelete(row) : null"
              (onClearClientToStationclick)="clearClientToStation(row)">
            </backoffice-table-actions>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <backoffice-table-pagination [totalPages]="totalPages" [currentPage]="currentPage + 1"
      (onPrevious)="onPreviousPage()" (onNext)="onNextPage()">
    </backoffice-table-pagination>
  </div>
</div>